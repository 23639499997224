import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment as env } from './../../environments/environment';

import { NavigationDataObject } from '../models/navigation.data-object';
import { UserData } from '../models/user.data-object';
import { Constants } from '../models/constants';

@Injectable({
  providedIn: 'root'
})
export class AppCoreService {
  
  private _navigationDataObject = new Subject<NavigationDataObject>();
  navData$ = this._navigationDataObject.asObservable();
  lastNavigationData: NavigationDataObject;
  isLanguageSelected = new BehaviorSubject<boolean>(this.languageSelected());
  isUserLoggedIn = new BehaviorSubject<boolean>(this.checkUserData());
  isNavigationActive = new BehaviorSubject<string>(this.Global.DEFAULT_NAV_TXT);

  storeNavigations(value: NavigationDataObject): void {
    this.lastNavigationData = value;
    this._navigationDataObject.next(value);
  }

  constructor(private Global: Constants) {

  }
  setSetNavigationActive(arg0: string): any {
    localStorage.setItem(this.Global.ACTIVE_NAV_TXT, arg0)
    this.isNavigationActive.next(arg0);
  }
  getSetNavigationActive(): any {
    const data = localStorage.getItem(this.Global.ACTIVE_NAV_TXT);
    if(data){     
      this.isNavigationActive.next(data);
      return this.isNavigationActive.asObservable(); 
    }else{
      return this.isNavigationActive.asObservable(); 
    }
       
  }
  // Languages section - start
  private languageSelected(): boolean {
    const saved_token = !!localStorage.getItem(this.Global.LANGUAGE);
    if (saved_token) {
      const selectedLanguage = localStorage.getItem(this.Global.LANGUAGE);
      if (selectedLanguage !== undefined || selectedLanguage !== '' ) {
        return true;
      }
    }
    return false;
  }

  public GetLanguage(): any {
    return localStorage.getItem(this.Global.LANGUAGE);
  }

  public CheckLanguageSelected(): Observable<boolean> {
    return this.isLanguageSelected.asObservable();
  }

  public SetLanguage(value): void {
    localStorage.setItem(this.Global.LANGUAGE, value);
    // this.tokenData.next(_tokenData);
    this.isLanguageSelected.next(true);
  }

  public removeLanguage(): void {
    localStorage.removeItem(this.Global.LANGUAGE);
    this.isLanguageSelected.next(false);
  }
  // Languages section - end

  // User section - start
  private checkUserData(): boolean {
    const saved_token = !!localStorage.getItem(this.Global.USER_DATA_TXT);
    if (saved_token) {
      const userData: UserData = JSON.parse(localStorage.getItem(this.Global.USER_DATA_TXT));
      return userData.isUserLoggedIn;
    }
    return false;
  }

  public CheckUserLoggedIn(): Observable<boolean> {
    return this.isUserLoggedIn.asObservable();
  }

  public GetUserData(): UserData {
    const userData: UserData = JSON.parse(localStorage.getItem(this.Global.USER_DATA_TXT));
    return userData;
  }

  public SetUserData(value): void {
    const userData = JSON.stringify(value);
    localStorage.setItem(this.Global.USER_DATA_TXT, userData);
    this.isUserLoggedIn.next(true);
  }

  public GetUserEmailMobile(): any {
    return localStorage.getItem(this.Global.USEREMAILMOBILE_DATA_TXT);
  }
  public SetUserEmailMobile(value): void {
    const userData = value;
    localStorage.setItem(this.Global.USEREMAILMOBILE_DATA_TXT, userData);
  }

  public RemoveUserData(): void {
    localStorage.removeItem(this.Global.USER_DATA_TXT);
    this.isUserLoggedIn.next(false);
  }
  /**
   * @description Check token value and validity and User flag 
   * then return user validity
   * as of now isTokenValid: true, once tokenizaition will be enabled, the default value will be false
   * @returns user validity: true
   */
  isUserAuthenticated(): boolean {
    let isTokenValid = true;
    const isTokenEnable = env.apiTokenizationEnabled ? true : false;
      
    if(isTokenEnable){
      // TODO:
      isTokenValid = true;
    }
    return  this.getUserLoggedInStatus() && isTokenValid;
  }
  getUserLoggedInStatus() : boolean {
    return this.GetUserData()===null ? false: this.GetUserData().isUserLoggedIn;
  }
}
