export const MESSAGES_EN = {
  errorMessageEmailOrMobileReq: 'Please enter Email ID or Mobile Number to Login',
  errorMessagePlanEmailOrMobileReq: 'Please enter Email ID or Mobile Number to Sign Up',
  errorMessageEmailOrMobileValid: 'please enter valid Email ID or Mobile Number',
  errorMessageRegisterEmailOrMobile: 'This is not a registered user Email ID/Mobile Number.',
  errorMessageOTP: 'OTP has been sent successfully.',
  errorMessagePassword: 'Please enter your password',
  errorMessagePasswordNotMatch: 'Passwords dont match',
  errorMessageUserPresent: 'User already present, please try to login',
  errorMessageEmailReq: 'Please enter your email address',
  errorMessagePasswordReq: 'Please enter your password',
  errorMessageIncorrectCred: 'Incorrect Credentials',
  errorMessageOTPReq: 'OTP required',
  errorMessageUserReq: 'User is not registered',
  successMessageProfileUpdated: 'Profile updated successfully.',
  errorMessageProfileUpdated: 'Some problem while updating the profile, please try again',
  errorPlanReq: 'Please select plan',
  errorExistingPlan: 'An active plan has already been associated with you. You can change the plan, once the active plan is expired.',
  errorExistingPlannew: 'An active plan has already been associated with you. You can purches another the plan',
  errorSelectPlanMessage: 'Selected plan already purchased. Please select other plan.',
  errorLoginMessage: 'Sorry, an error has occured. please try again.',
  enterEmailPhonenMessage: 'Please enter Email ID or Phone Number to Login',
  pdEmailOrMobile: 'Email Id or mobile number *',
  pdSignUp: 'Sign Up',
  pdSignIn: 'Sign In',

  pdSignUpUsing: 'OR<br>SIGN UP USING',
  HDVideos: 'Watch the first 2 videos absolutely free',
  WatchPhone: 'Get UNLIMITED access to hundreds of Full Movies / Mini Movies / Clips',
  DayFree: 'Watch Unlimited videos free for 48 hours',
  allPromos: 'Watch videos in low, medium and high-quality.',
  watchAnywhere: 'Use all features of the app',
  CancelAtAnyTime: 'No commitment, Cancel at any time',
  freedownload: 'Download videos to watch offline on Android | iOS.',
  // JoinFree:'Join free for 7 days',
  msgfreeLimit: 'Your free trial has expired, please subscribe to access Dollywood Play',
  msgsubscriblePlan: 'Your subscription plan has expired. Subscribe now to continue. Go to plans',
  msgOneMonth: '1 Month',
  msg12Months: '12 Months',
  msg10days: '1 Week',
  PleaseLogintoSubscribe: 'Please subscribe to continue',
  txtSubscribe: 'Subscribe',
  msgName: 'Name',
  msgMessage: 'Message',
  msgMobile: 'Mobile',
  msgEmailId: 'Email Id',
  msg6Months: '6 Months',
  msgMonthlyCharges: 'Charge apply only after 48 hours free unlimited viewing.',
  msgLogin: 'click here to login.',
  msgAlreadyPresentUser: 'User is already present in the system. Please',
  msgConfirmCode: 'Please enter the confirmation code we sent to your registered User Email ID/Mobile Number.',
  msgIncorrectOTP: 'Incorrect OTP! Please try again. ',
  msgNotReceiveOTP: 'Didnt receive OTP? Click here to resend.',
  msgSubmit: 'SUBMIT',
  msgUserLoggedIn: 'User logged in successfully',
  txtOTP: 'OTP',
  txtOfferedIn: 'Offered In',
  txtPerDay: '1 Per Day',

  msgUserOrder: 'Order',
  msgUserOrders: 'Orders',
  msgUserActiveOrders: 'Active Orders',
  msgUserOrderDate: 'Date',
  msgUserOrderDescription: 'Description',
  msgUserServicePeriod: 'Service Period',
  msgUserPaymentMethod: 'Payment Method',
  msgUserTotal: 'Total',
  msgUserExpiredOrder: 'Expired Orders',
  msgInvoice: 'Invoice downloaded successfully',
  msgActiveOrders: 'No active orders present',
  msgNoRecordFounds: 'No records found',

  msgPrintInvoice: 'Print Invoice',
  msgUserOrderStatus: 'Status',
  msgNoDataFound: 'No Data Found',


  myAccount: 'Account',
  myVideos: 'Videos',
  myWelcome: 'Welcome',
  txtEditProfile: 'Edit Profile',
  mySignOut: 'Sign Out',
  txtLogOut: 'Are you sure, you want to log out ?',
  myCancel: 'Cancel',



  txtLogin: 'Login',
  txtFavourites: 'Favourites',
  txtWatchList: 'Watch List',
  txtPlans: 'Plans',
  txtOrders: 'Orders',
  txtMyAccount: 'My Account',
  txtSignOut: 'Sign Out',
  msgSignOut: 'Are you sure you want to sign out?',
  txtCancel: 'Cancel',
  txtYes: 'Yes',
  txtNo: 'No',
  txtok: 'Ok',
  txtShare: 'Share',
  txtDuration: 'Duration',
  txtRemoveFav: 'Are you sure you want to remove this item from favorite?',
  txtAssetRemovedFav: 'Video is removed from favorites',
  txtAssetAlreadyAddedFav: 'Video is already added to favorites',
  txtPleaseLogin: 'Please sign in to continue',
  txtAssetAddedFav: 'Video is added to favorites',
  txtSuggestedVideo: 'Suggested Video',
  txtRelatedVideo: 'Related Video',
  txtActors: 'Actors',
  txtDetail: 'Detail',
  watchNow: 'WATCH NOW',
  txtNoResult: 'No result found',
  txtTotalResult: 'Total results',
  txtSearchResult: 'Search results for',
  txtWatchNow: 'WATCH NOW',

  txtOops: 'Oops!',
  txtPageNotFound: 'Sorry, an error has occured, Requested page not found!',
  txtTakeMeHome: 'Take Me Home',
  txt404: '404 Not Found',

  txtMoreResults: 'Search',
  txtCategories: 'Categories',

  txtAboutUs: 'About Us',
  txtPrivacyPolicy: 'Privacy Policy',
  txtTermsofUse: 'Terms & Conditions',
  txtDisclaimer: 'Disclaimer',
  txtContactUs: 'Contact Us',
  txtGotit: 'Got it',
  txtCookieMsg: 'This website uses cookies to ensure you get the best experience on our website.',
  txtLearnMore: 'Learn More.',
  txtAllRightsReserved: 'All Rights Reserved.',
  txtClickAndFind: 'Also watch your favourite Dollywood Play videos on android | ios.',

  txtPlay: 'Play',
  txtMovies: 'Movies',
  txtVideos: 'Videos',
  txtWelcome: 'Welcome',
  txtPackageUsing: 'Package Using',
  txtExpiresOn: 'Expires On',

  txtUploadPicture: 'Upload Picture',
  txtDeletePicture: 'Delete Picture',
  txtDeletePhotoMsg: 'Are you sure you want to delete photo',
  txtSave: 'SAVE',
  txtAboutMe: 'About Me',
  txtEmailOrMobile: 'Registered Email Id or Mobile Number',
  txtGender: 'Gender',

  txtUserName: 'Name',
  txtEmailId: 'Please enter valid email address',
  txtNameReq: 'Please enter valid name',
  txtMobile: 'Please enter valid phone number',
  txtMessage: 'please enter message',
  txtValidMobile: 'Please enter valid mobile number',
  txtValidEmail: 'please enter valid Email Id',
  txtmsgSuccess: 'Thank you for reaching out to us, we will get back to you shortly',
  txtErrorMsg: 'Some problem while updating the profile, please try again',
  txtLimitedTime: 'for Limited Period Only',

  txtNameValidNamePattern: 'please enter valid name',
  txtProfilePhotoMsg: 'Profile photo updated successfully',
  errorMessageProfilePhotoUpdated: 'Some problem while updating the profile photo, please try again',

  successMessageProfilePhotoDeleted: 'Profile photo deleted successfully',
  errorMessageProfilePhotoDeleted: 'Some problem while deleting the profile photo, please try again',

  txtMale: 'Male',
  txtFemale: 'Female',
  txtOther: 'Other',

  breadcrumbHome: 'HOME',
  breadcrumbAccount: 'EDIT ACCOUNT',
  breadcrumbAccountInfo: 'ACCOUNT',
  breadcrumbContactUs: 'CONTACT US',
  breadcrumbFavourites: 'FAVOURITES',
  breadcrumbCategories: 'CATEGORIES',
  breadcrumbOrder: 'ORDER',
  breadcrumbOrders: 'ORDERS',
  breadcrumbSearch: 'SEARCH',
  breadcrumbPlans: 'PLANS',
  breadcrumbHistory: 'HISTORY',

  breadcrumbPrivacyPolicy: 'PRIVACY POLICY',
  breadcrumbTermsConditions: 'TERMS & CONDITIONS',
  breadcrumbDisclaimer: 'DISCLAIMER',

  breadcrumbAboutUs: 'ABOUT US',
  breadcrumbHelpFaq: 'HELP & FAQs',
  breadcrumbSitemap: 'SITEMAP',
  breadcrumbApps: 'APPS',
  txtViewALL: 'VIEW ALL',
  txtWatchContent: 'You need to be an adult to watch this content. Are you 18+ ?',
  txtFooterPrivacyPolicy: 'Privacy Policy',
  txtFooterTermsConditions: 'Terms & Conditions',
  txtFooterDisclaimer: 'Disclaimer',
  txtFooterAboutUs: 'About Us',
  txtFooterHelpFaq: 'Help & FAQs',
  txtFooterSitemap: 'Sitemap',

  txtLoginFooterMsg: ' Join now to watch FREE - Unlimited Full Movies/ Mini Movies/Clips for 7 days - Any number of promos on the app.',
  txtSigninTNCMsg: "By signing up, you agree to the Terms & Conditions",
  txtSocialLogin: "Or Sign In Using",
  txtLoginButtonMsg: "Sign In",
  txtLoginTNCMsgPart1: "By clicking Sign in, you agree to our",
  txtLoginTNCMsgPart2: "and that you have read our",
  txtLoginPlaceholderMsg: "Email or Phone",


  txtSearchFor: 'Enter the terms you wish to search for.',
  txtProfileTitle: 'Profile',
  watchTrailer: 'Watch Trailer',
  starCast: 'Star Cast',
  watchMovie: 'Watch Movie',
  watchEpisode: 'Watch Episode',
  seasons:'Seasons',
  directorName: 'Director',
  genre: 'Genre',
  moreResults: 'More Results',
  VIEW_ALL: 'VIEW ALL',
  ENGLISH_TXT: 'ENG',
  contact_success_msg: 'Your message has been sent, Our team will conntact soon',
  wrong_toastr_msg: 'Something went wrong'
};



export const MESSAGES_HI = {
  txtLimitedTime: 'केवल सीमित अवधि के लिए',
  errorMessageEmailOrMobileReq: 'कृपया अपना ईमेल आईडी या मोबाइल नंबर दर्ज करें',
  errorMessagePlanEmailOrMobileReq: 'कृपया साइन अप करने के लिए ईमेल आईडी या फोन नंबर दर्ज करें',
  errorMessageEmailOrMobileValid: 'कृपया मान्य ईमेल आईडी या मोबाइल नंबर दर्ज करें',
  errorMessageRegisterEmailOrMobile: 'यह एक पंजीकृत उपयोगकर्ता ईमेल आईडी / मोबाइल नंबर नहीं है।',
  errorMessageOTP: '',
  errorMessagePassword: 'कृपया अपना पासवर्ड दर्ज करें',
  errorMessagePasswordNotMatch: 'पासवर्ड का मिलान न करें',
  errorMessageUserPresent: 'उपयोगकर्ता पहले से मौजूद है, कृपया लॉगिन करने का प्रयास करें',
  errorMessageEmailReq: 'अपना ईमेल भरो',
  errorMessagePasswordReq: 'कृपया अपना पासवर्ड दर्ज करें',
  errorMessageIncorrectCred: 'गलत परिचय',
  errorMessageOTPReq: 'ओटीपी की आवश्यकता है',
  errorMessageUserReq: 'उपयोगकर्ता पंजीकृत नहीं है',
  successMessageProfileUpdated: 'प्रोफाइल को सफलतापूर्वक अपडेट किया गया।',
  errorMessageProfileUpdated: 'प्रोफ़ाइल अपडेट करते समय कुछ समस्या, कृपया पुनः प्रयास करें',
  errorPlanReq: 'Please select plan',
  errorExistingPlan: 'एक सक्रिय प्लान आपके साथ जुड़ा हुआ है। ये समाप्त हो जाने पर, आप प्लान को बदल सकते हैं। ',
  errorExistingPlannew: 'एक सक्रिय प्लान आपके साथ जुड़ा हुआ है।  ',
  errorSelectPlanMessage: 'Selected plan already purchased. Please select other plan.',
  enterEmailPhonenMessage: 'कृपया अपना ईमेल आईडी या मोबाइल नंबर दर्ज करें',
  pdEmailOrMobile: 'ईमेल आईडी या मोबाइल नंबर',
  pdSignUp: 'साइन अप करें',
  PleaseLogintoSubscribe: 'कृपया जारी रखने के लिए सदस्यता लें',
  txtSubscribe: 'सदस्यता लें',
  pdSignIn: 'साइन इन करें',
  pdSignUpUsing: 'या <br> साइन अप करें',
  HDVideos: 'साइन अप करने पर पहले 2 वीडियो बिल्कुल मुफ्त देखें',
  WatchPhone: 'सैकड़ों फुल मूवी / मिनी मूवी / क्लिप्स तक पहुँच प्राप्त करें',
  DayFree: '48 घंटे के लिए असीमित वीडियो मुफ्त',
  CancelAtAnyTime: 'कोई प्रतिबद्धता नहीं, किसी भी समय रद्द करें',
  allPromos: 'वीडियो को निम्न, मध्यम और उच्च-गुणवत्ता में देखें।',
  watchAnywhere: 'एप्लिकेशन की सभी सुविधाओं का उपयोग करें',
  freedownload: 'Android | iOS पर ऑफ़लाइन देखने के लिए वीडियो डाउनलोड करें।',
  msgfreeLimit: 'आपकी 48 घंटे की मुफ्त सीमा समाप्त हो गई है, कृपया डॉलिवुड प्ले का उपयोग करने के लिए सदस्यता लें',
  msgsubscriblePlan: 'आपकी सदस्यता योजना की समय सीमा समाप्त हो गई है। अब जारी रखें। योजनाओं को जारी रखें',

  msgOneMonth: '1 महीना',
  msg12Months: '12 महीना',

  msgName: 'नाम',
  msgMessage: 'संदेश',
  msgMobile: ' मोबाइल नंबर',
  msgEmailId: 'ईमेल आईडी',
  msg10days: '1 सप्ताह',
  msg6Months: '6 महीना',

  msgMonthlyCharges: 'पहले 48 घंटे के बाद मासिक शुल्क',
  msgLogin: 'लॉगिन करने के लिए यहां क्लिक करें',
  msgAlreadyPresentUser: 'उपयोगकर्ता पहले से ही सिस्टम में मौजूद है। कृपया',
  msgConfirmCode: 'ओ टी पी आपके रजिस्टर्ड मोबाइल नंबर / ईमेल  पर भेजा हुआ है।',
  msgIncorrectOTP: 'आपके द्वारा दर्ज किया गया OTP गलत है, कृपया पुनः प्रयास करें',
  msgNotReceiveOTP: 'ओ टी पी प्राप्त नहीं हुआ? यहां क्लिक करें',
  msgSubmit: 'जमा करें',
  msgUserLoggedIn: 'उपयोगकर्ता सफलतापूर्वक लॉग इन किया',
  txtOTP: 'ओटीपी',
  txtOfferedIn: 'इन भाषाओं में इस्तेमाल किया जा सकता है।',
  txtPerDay: '1 प्रति दिन',

  msgUserOrder: 'ऑर्डर',
  msgUserOrders: 'ऑर्डर',
  msgUserActiveOrders: 'सक्रिय ऑर्डर',
  msgUserOrderDate: 'दिनांक',
  msgUserOrderDescription: 'वर्णन',
  msgUserServicePeriod: 'सेवा अवधि',
  msgUserPaymentMethod: 'भुगतान का तरीका',
  msgUserTotal: 'कुल रकम',
  msgUserExpiredOrder: 'एक्स्पायर्ड ऑर्डर',
  msgInvoice: 'चालान सफलतापूर्वक डाउनलोड किया गया',
  msgActiveOrders: 'कोई सक्रिय आदेश मौजूद नहीं है',
  msgNoRecordFounds: 'कोई रिकॉर्ड नहीं मिला',
  msgPrintInvoice: 'प्रिन्ट इनवॉइस ',
  msgUserOrderStatus: 'स्थिति',
  msgNoDataFound:'कोई डेटा उपलब्ध नहीं है',

  myAccount: 'अपना खाता ',
  myVideos: 'विडिओ ',
  myWelcome: 'स्वागत ',
  myEditProfile: 'प्रोफाइल एडिट करें',
  mySignOut: 'साइन आउट',
  mylogOut: 'साइन आउट करे ?',
  myCancel: 'रद्द करें',


  txtLogin: 'लॉग इन करें',
  txtFavourites: 'पसंदीदा',
  txtWatchList: 'वॉचलिस्ट ',
  txtPlans: 'प्लान ',
  txtOrders: 'ऑर्डर',
  txtMyAccount: 'मेरा खाता',
  txtSignOut: 'साइन आउट',
  msgSignOut: 'क्या आप द्वारा साइन आउट किया जाना सुनिश्चित है',
  txtCancel: 'रद्द करें',
  txtYes: 'हाँ',
  txtok: 'हाँ',
  txtShare: 'शेयर',
  txtDuration: 'अवधि',
  txtRemoveFav: 'क्या आप वाकई इस आइटम को पसंदीदा से हटाना चाहते हैं?',
  txtNo: 'ना',

  txtAssetRemovedFav: 'आपका वीडियो पसंदीदा से हटा दिया गया है',
  txtAssetAlreadyAddedFav: 'वीडियो पहले से ही पसंदीदा में जोड़ी गई है',
  txtPleaseLogin: 'कृपया जारी रखने के लिए साइन इन करें',
  txtAssetAddedFav: 'आपका वीडियो पसंदीदा में जोड़ दिया गया है ',
  txtSuggestedVideo: 'हमारा सुझाव  ',
  txtRelatedVideo: 'संबंधित वीडियो',
  txtActors: 'अभिनेता',
  txtDetail: 'विस्तार',

  txtNoResult: 'कोई परिणाम नहीं मिला',
  txtTotalResult: 'कुल परिणाम',
  txtSearchResult: 'के लिए खोज परिणाम',
  txtWatchNow: 'अभी देखे',

  txtOops: 'ऊप्स!',
  txtPageNotFound: 'क्षमा करें, एक त्रुटि हुई, अनुरोधित पृष्ठ नहीं मिला!',
  txtTakeMeHome: 'मुझे घर ले चलो',
  txt404: '404 नहीं मिला',

  txtMoreResults: 'खोज',
  txtCategories: 'श्रेणियाँ',
  watchNow: 'अभी देखे',

  txtAboutUs: 'हमारे बारे में',
  txtPrivacyPolicy: 'प्राइवेसी पॉलिसी',
  txtTermsofUse: 'उपयोग की शर्तें',
  txtDisclaimer: 'अस्वीकरण',
  txtContactUs: 'संपर्क करें',
  txtGotit: 'सेहमत हैं।',
  txtCookieMsg: 'यह वेबसाइट यह सुनिश्चित करने के लिए कुकीज़ का उपयोग करती है कि आपको हमारी वेबसाइट पर सबसे अच्छा अनुभव मिले।',
  txtLearnMore: 'और अधिक जानें।',
  txtAllRightsReserved: 'कॉपीराइट सुरक्षित।',
  txtClickAndFind: 'देखिये आपके पसंदीदा वीडियो मोबाइल ऐप पर।',

  txtPlay: 'प्ले',
  txtLogOut: 'क्या आप लॉग आउट करना चाहते हैं ?',
  txtEditProfile: 'प्रोफाइल एडिट करें',
  txtMovies: 'चलचित्र',
  txtVideos: 'वीडियो',
  txtWelcome: 'स्वागत है।',
  txtPackageUsing: 'सक्रिय पैकेज',
  txtExpiresOn: 'सीमा समाप्त समय',

  txtUploadPicture: 'तसवीर डालें',
  txtDeletePicture: 'तसवीर हटाएं',
  txtDeletePhotoMsg: 'क्या आप वाकई फ़ोटो हटाना चाहते हैं',
  txtSave: 'जमा करें',
  txtAboutMe: 'मेरे बारे में',
  txtEmailOrMobile: 'पंजीकृत ईमेल आईडी या मोबाइल नंबर',
  txtGender: 'लिंग',
  txtUserName: 'नाम',
  txtNameReq: 'कृपया नाम दर्ज करें',
  txtEmailId: 'कृपया मान्य ईमेल नंबर दर्ज करें',
  txtMobile: 'कृपया मान्य फ़ोन नंबर दर्ज करें',
  txtMessage: 'कृपया संदेश दर्ज करें',
  txtValidMobile: 'कृपया मान्य मोबाइल नंबर दर्ज करें',
  txtValidEmail: 'कृपया मान्य ईमेल आईडी दर्ज करें',
  txtmsgSuccess: 'धन्यवाद हमारे तक पहुंचने के लिए, हम शीघ्र ही आपके पास वापस आएंगे',
  txtErrorMsg: 'प्रोफ़ाइल अपडेट करते समय कुछ समस्या, कृपया पुनः प्रयास करें',



  txtNameValidNamePattern: 'कृपया मान्य नाम दर्ज करें',
  txtProfilePhotoMsg: 'प्रोफ़ाइल फ़ोटो सफलतापूर्वक अपडेट की गई',
  errorMessageProfilePhotoUpdated: 'प्रोफ़ाइल फ़ोटो को अपडेट करते समय कुछ समस्या, कृपया पुनः प्रयास करें',
  successMessageProfilePhotoDeleted: 'प्रोफ़ाइल फ़ोटो सफलतापूर्वक हटा दी गई',
  errorMessageProfilePhotoDeleted: 'प्रोफ़ाइल फ़ोटो हटाते समय कुछ समस्या, कृपया पुनः प्रयास करें',

  txtMale: 'पुरुष',
  txtFemale: 'महिला',
  txtOther: 'अन्य',
  breadcrumbHome: 'होम',
  breadcrumbAccount: 'खाता संपादित करें',
  breadcrumbAccountInfo: 'लेखा',
  breadcrumbContactUs: 'हमसे संपर्क करें',
  breadcrumbFavourites: 'पसंदीदा',
  breadcrumbCategories: 'श्रेणियाँ',
  breadcrumbOrder: 'ऑर्डर',
  breadcrumbOrders: 'ऑर्डर',
  breadcrumbSearch: 'खोज',
  breadcrumbPlans: ' प्लान ',
  breadcrumbHistory: 'वॉच लिस्ट ',
  txtViewALL: 'मोअर',
  txtWatchContent: 'इस सामग्री को देखने के लिए आपको एक वयस्क होने की आवश्यकता है। क्या आप 18+ हैं?',

  breadcrumbPrivacyPolicy: 'गोपनीयता नीति',
  breadcrumbTermsConditions: 'नियम एवं शर्तें',
  breadcrumbDisclaimer: 'अस्वीकरण',
  breadcrumbAboutUs: 'हमारे बारे में',
  breadcrumbHelpFaq: 'मदद और पूछे जाने वाले प्रश्न',
  breadcrumbSitemap: 'SITEMAP',
  breadcrumbApps: 'ऐप्स',

  txtFooterPrivacyPolicy: 'गोपनीयता नीति',
  txtFooterTermsConditions: 'नियम एवं शर्तें',
  txtFooterDisclaimer: 'अस्वीकरण',
  txtFooterAboutUs: 'हमारे बारे में',
  txtFooterHelpFaq: 'मदद और पूछे जाने वाले प्रश्न',
  txtFooterSitemap: 'Sitemap',

  txtSearchFor: 'Enter the terms you wish to search for.',
  txtProfileTitle: 'प्रोफाइल',
  watchTrailer: 'ट्रेलर देखिये',
  starCast: 'स्टार कास्ट',
  watchMovie: 'फ़िल्म देखिये',
  watchEpisode: 'एपिसोड देखिये',
  seasons: 'सीज़न्स',
  directorName: 'डायरेक्टर',
  genre: 'प्रकार',
  moreResults: 'और नतीजे',
  VIEW_ALL: 'सभी देखें',
  HINDI_TXT: 'हिंदी',
  andText: 'और',

  txtLoginFooterMsg: ' मुफ़्त देखने के लिए अभी सम्मिलित हों  - 7 दिनों के लिए अनलिमिटेड फुल मूवी / मिनी मूवी / क्लिप्स  - ऐप पर कितने भी प्रोमो देंखे।',
  txtSigninTNCMsg: "साइन अप करके, आप नियम और शर्तों से सहमत हैं",
  txtSocialLogin: "या  यहाँ साइन इन करे।",
  txtLoginButtonMsg: "साइन इन करे",
  txtLoginTNCMsgPart1: "साइन इन पर क्लिक करके, आप हमारी",
  txtLoginTNCMsgPart2: " के प्रति अपनी सहमति देते हैं।",
  txtLoginPlaceholderMsg: "ईमेल या फ़ोन नंबर",

  errorLoginMessage: 'क्षमा करें, एक त्रुटि हुई. कृपया पुनः प्रयास करें.',
  contactSuccessMsg: 'आपकी संदेश भेज दी गई है, हमारी टीम जल्द ही कॉन्टेक्ट करेगी',
  wrongToastrMsg: 'कुछ गलत हो गया'
};
