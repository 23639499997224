import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from './../../environments/environment';
import { AppTokenService } from './app-token.service';

@Injectable()
export class AssetService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _appTokenService: AppTokenService;
  _assetDetailsEndPoint = 'v1/asset/';
  _assetFavouriteEndPoint = 'v1/assetfavourite';
  _assetGroupFavouriteEndPoint = 'v1/assetgroupfavourite';
  _removeAssetGroupFavEndPoint = 'v1/assetgroupfavourite/delete';
  _removeFavouriteEndPoint = 'v1/assetfavourite/delete';
  _addWatchListEndPoint = 'v1/watchlist';
  _getWatchListEndPoint = 'v1/watchlist';
  _relatedVideoEndPoint = 'v1/asset/related';
  _suggestedVideosEndPoint = 'v1/asset/suggested';
  _castCrewEndPoint = 'v1/cast';
  _bookmarkEndPoint = 'v1/bookmark';
  _assetGroupDetailsEndPoint = 'v1/assetgroup';
  _assetListEndPoint = 'v1/listing';
  _assetCategoryEndPoint = 'v1/assetcategory';

  constructor(private httpHandler: HttpHandlerService, private  appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
    this._appTokenService = appTokenService;
  }

  public GetAssetDetails(path, urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._assetDetailsEndPoint + path + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetFavourites(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._assetFavouriteEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public AddToFavourite(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._assetFavouriteEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      urlParam
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public AddAssetGroupToFavourite(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._assetGroupFavouriteEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      urlParam
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public RemoveAssetGroupFromFavourite(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._removeAssetGroupFavEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      urlParam
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public RemoveFromFavourite(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._removeFavouriteEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      urlParam
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  

  public GetRelatedVideo(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._relatedVideoEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetSuggestedVideos(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._suggestedVideosEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetCastCrews(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._castCrewEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public AddToBookmark(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._bookmarkEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      urlParam
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetAssetGroupDetails(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._assetGroupDetailsEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetAssetCategory(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._assetCategoryEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetAssetListing(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._assetListEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  // add user and asset in watch list
  public AddToWatchList(requestObject): Observable<any> {
    const full_api_url = this._apiUrl + this._addWatchListEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      requestObject
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  // get watch list
  public GetWatchList(filterObject): Observable<any> {
    const full_api_url = this._apiUrl + this._getWatchListEndPoint + filterObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }
}
