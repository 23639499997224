import { FooterContentComponent } from './components/footer-content/footer-content.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { SearchComponent } from './components/search/search.component';
import { AssetGroupDetailsComponent } from './components/asset-group-details/asset-group-details.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(a => a.ContactUsModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(a => a.AuthModule)
  },
  {
    path: 'user/auth',
    loadChildren: () => import('./modules/auth/auth.module').then(a => a.AuthModule)
  },
  { path: 'search/:query', component: SearchComponent },
  { path: 'home', loadChildren: ()=> import('./modules/home/home.module').then(hm => hm.HomeModule) },
  // { path: 'menu/:path/:type', loadChildren: ()=> import('./modules/home/home.module').then(hm => hm.HomeModule) },
  { path: 'footer/:title/:path', component: FooterContentComponent },
  {
    path: 'genre',
    loadChildren: () => import('./modules/genre/genre.module').then(gl => gl.GenreModule)
  },
  {
    path: 'genres',
    loadChildren: () => import('./modules/genre/genre.module').then(gl => gl.GenreModule)
  },
  {
    path: 'category/:title/:path',
    loadChildren: () => import('./modules/page-category/page-category.module').then(pc => pc.PageCategoryModule)
  },
  {
    path: 'video/:title/:path',
    loadChildren: () => import('./modules/video-details/video-details.module').then(vd => vd.VideoDetailsModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./modules/language/language.module').then(l => l.LanguageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then(u => u.UserModule)
  },
  {
    path: 'plans',
    loadChildren: () => import('./modules/subscriptions/subscriptions.module').then(s => s.SubscriptionsModule)
  },
  {
    path: ':name',
    loadChildren: ()=> import('./modules/home/home.module').then(hm => hm.HomeModule)
  },
  { path: 'season/:title/:path', component: AssetGroupDetailsComponent }  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    enableTracing: false
    ,preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
