export class CarouselResponse {
    success: boolean;
    message: string;
    data: CarouselDataObject[];
}

export class CarouselDataObject {
    path: string;
    title: string;
    description: string;
    isActive: any;
    exclusionText: string;
    sameForAllZone: any;
    displayIndexing: string;
    sameForAllProductType: any;
    cdlmId: any;
    fileName: string;
    filePath: string;
    dtypeId: any;
    langId: any;
    isVisible: any;
    isPrimaryNavigationVisible: any;
    isMenuCategoryVisible: any;
    isAssetGroupVisible: any;
    isAssetCategoryVisible: any;
    isPageCategoryVisible: any;
    label: string;
    isButtonRequired: any;
    clickThrough: string;
    confDisplayIndexing: any;
    caption: string;
    carouselType: string;
    confPrimaryNavigation: any;
    confMenuCategory: string;
    confAssetGroup: string;
    confAssetCategory: string;
    confAsset: string;
    confTrailerVideo: string;
    uploadImage: any;
    playbackUrl: string;
    type: string;
}
