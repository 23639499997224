import { AssetGroupClassification } from './asset.data-object';

export class VideoPageDataObject {
    path: any;
    uplynkId: string;
    title: string;
    description: string;
    isActive: any;
    adlmId: any;
    deviceName: string;
    vodOrLivePosterImageFileName: string;
    vodOrLivePosterImageFilePath: string;
    vodClosedCaptionFileName: string;
    vodClosedCaptionFilePath: string;
    verticalFileName: string;
    verticalFilePath: string;
    horizontalFileName: string;
    horizontalFilePath: string;
    langaugeId: any;
    langaugeName: string;
    langaugeCode: string;
    dtypeId: any;
    langId: any;
    exclusionText: string;
    sameForAllZone: any;
    sameForAllProductType: any;
    displayIndexing: any;
    label: string;
    airStartDate: string;
    airStartTimezone: string;
    airEndDate: string;
    airEndTimezone: string;
    offlineDownload: any;
    mapTrailerVideo: string;
    uploadVerticalImage: string;
    uploadHorizontalImage: string;
    seoTitle: string;
    seoKeyword: string;
    seoDescription: string;
    contentType: string;
    ingestionType: string;
    assetType: string;
    vodOrLivePosterImage: string;
    vodAssetTitle: string;
    vodClosedCaption: any;
    vodIsDrm: any;
    vodAssetDesc: string;
    vodOrLiveAdServer: any;
    vodPreRollAds: any;
    vodPostRollAds: 0
    copyrightText: string;
    liveType: string;
    liveVirtualChannelId: any;
    liveEventId: any;
    epgUrl: string;
    hlsUrl: string;
    lyrics: string;
    duration: any;
    certificationMappedCount: any;
    bookmarkDuration: any;
    ratingCount: any;
    totalRating: any;
    userTotalRating: any;
    likeCount: any;
    isUserLikes: any;
    watchedCount: any;
    isUserWatched: any;
    primaryNavigationMappedCount: any;
    menuCategoryMappedCount: any;
    genreMappedCount: any;
    assetGroupMappedCount: any;
    averageRating: any;
    certification: any;
    primaryNavigation: PrimaryNavigation[];
    menuCategory: MenuCategory[];
    assetGroup: [];
    csaiAdServer: string;
    genre: DataGenre[];
    playbackUrl: string;
    geoStatus: boolean;
    isPremium: boolean;
    casUserWatchAsset: boolean;
    subscription_id:any;

}

export class PrimaryNavigation {
    path?: string;
    type: string;
    primaryNavigationId?: string;
}

export class MenuCategory {
    path: string;
    type: string;
    menuCategoryId: string;
}

export class DataGenre {
    path: string;
    type: string;
    title: string;
    genreId: number;
    genreName: string;
}

export class RelatedVideo {
    status: boolean;
    data: VideoRelatedData[];
}


export class CastCrews {
    status: boolean;
    message: string;
    data: CastCrewsData[];
}

export class CastCrewsData {
    path: any;
    firstName: any;
    lastName: any;
    shortDescription: any;
    description: any;
    verticalFileName: any;
    verticalFilePath: any;
    horizontalFileName: any;
    horizontalFilePath: any;
    likeCount: any;
    isUserLikes: any;
    type: string;
    character: Character;
}

export class Character {
    path: any;
    castId: any;
    characterFirstName: string;
    characterLastName: string;
    shortDescription: any;
    description: any;
    professionTypeId: any;
    professionType: any;
    verticalFileName: any;
    verticalFilePath: any;
    horizontalFileName: any;
    horizontalFilePath: any;
    uploadVerticalImage: string;
    uploadHorizontalImage: string;
    type: any;
}

export class Breadcrumb {
    home: string;
    pri_nav: string;
    m_cat: string;
    a_group: string;
    a_cat: string;
    last_node: string;
}

export class BreadcrumbData {
    breadcrumb: Breadcrumb;
    breadcrumb_nids: Breadcrumb;
}

export class VideoRelatedData {
    path: string;
    title: string;
    type: string;
    created: string;
    alias: string;
    asset_group_classification: AssetGroupClassification[];
    duration: string;
    average_rating: number;
    likes_count: number;
    watched_count: number;
    genre: DataGenre[];
    device: string;
    same_for_all_orientation: boolean;
    asset_is_premium: boolean;
    display_indexing: string;
    license_config: [];
    order_by: string;
    sorting: string;
    user_can_see_asset: boolean;
}

export class VideoRelatedOrientation {
    description: string;
    image: string;
    image_horizontal: string;
    is_visible: boolean;
    label: string;
    name: string;
    title: string;
}
