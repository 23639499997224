import { env } from 'process';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable()
export class Constants {

  IMAGE_PREFIX = environment.imagePrefix;
  txtCancel = 'Cancel';
  txtYes = 'Yes';
  txtNo = 'No';
  txtok = 'Ok';
  txtShare = 'Share';
  txtDuration = 'Duration';
  txtRemoveFav = 'Are you sure you want to remove this item from favorite?';
  txtAssetRemovedFav = 'Video is removed from favorites';
  txtAssetAlreadyAddedFav = 'Video is already added to favorites';
  txtPleaseLogin = 'Please sign in to continue';
  txtAssetAddedFav = 'Video is added to favorites';
  txtSuggestedVideo = 'Suggested Video';
  txtRelatedVideo = 'Related Video';
  txtActors = 'Actors';
  txtDetail = 'Detail';
  txtNoResult = 'No result found';
  txtTotalResult = 'Total results';
  txtSearchResult = 'Search results for';
  txtWatchNow = 'WATCH NOW';
  LANG_HI = 'hi';
  LANG_EN = 'en';
  PAGE_CATEGORY_TYPE = 'page_category';
  WEB_DEVICE_ID = '1';

  // RazorPay terms
  currency = 'INR';
  receipt = 'rcptid_11';
  rpayName = 'Dollywood Play';
  rpayDesc = 'Purchase plan';
  appLogo = './assets/images/Dollywood-Play-logo.png';
  paymentMethod = 'razor';
  freeTrailSubId = 4;
  freeTrailOrderId = 0;

  defaultViewAllImgPath = '/assets/images/view_vertical.png';
  DEFAULT_SRC_FALLBACK = 'https://dollywoodplay.dcafecms.com/sites/default/files/2019-10/action_0_0.jpg';
  ACTIVE_NAV_TXT = 'active_nav';
  USER_DATA_TXT = 'ud';
  LANGUAGE = 'language';
  DEFAULT_NAV_TXT = 'HOME';
  USEREMAILMOBILE_DATA_TXT = 'em';
  // Default images
  defaultGenresImgPath = '/assets/images/default_image_genre.png';
  defaultPageCategoryImgPath ='/assets/images/default_image.png';

  public GetTitleForUrl(value: string): string {
      return value.replace(/\s/g , '-').toLowerCase();
  }
}
