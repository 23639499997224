import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { AssetGroupClassification } from './asset.data-object';
import { DataGenre } from './video-page.data-object';

export class TokenData {
    token: string;
    type: string;
    expires_at: Date;
}

export class UserData {
    isUserLoggedIn = false;
    userId = 0;
}

export class WatchListDataObject {
    status: boolean;
    data: AllData[];
}

export class AllData {
    path: string;
    type: string;
    created: string;
    alias: string;
    asset_group_classification: AssetGroupClassification[];
    duration: string;
    air_date: string;
    end_date: string;
    average_rating: number;
    likes_count: number;
    watched_count: number;
    genre: DataGenre[];
    device: string;
    same_for_all_orientation: boolean;
    asset_is_premium: boolean;
    display_indexing: string;
    license_config: [];
    orientation: DataOrientation[];
    order_by: string;
    sorting: string;
    user_can_see_asset: boolean;
}

export class DataOrientation {
    description: string;
    image: string;
    image_horizontal: string;
    is_visible: boolean;
    label: string;
    name: string;
    title: string;
}