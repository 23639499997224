import { Genre, Orientation } from './genre.data-object';
import { DataGenre, MenuCategory, PrimaryNavigation } from './video-page.data-object';

export class VideoPageData {
    path: any;
    uplynkId: string;
    title: string;
    description: string;
    isActive: any;
    adlmId: any;
    deviceName: string;
    vodOrLivePosterImageFileName: string;
    vodOrLivePosterImageFilePath: string;
    vodClosedCaptionFileName: string;
    vodClosedCaptionFilePath: string;
    verticalFileName: string;
    verticalFilePath: string;
    horizontalFileName: string;
    horizontalFilePath: string;
    langaugeId: any;
    langaugeName: string;
    langaugeCode: string;
    dtypeId: any;
    langId: any;
    exclusionText: string;
    sameForAllZone: any;
    sameForAllProductType: any;
    displayIndexing: any;
    label: string;
    airStartDate: string;
    airStartTimezone: string;
    airEndDate: string;
    airEndTimezone: string;
    offlineDownload: any;
    mapTrailerVideo: string;
    uploadVerticalImage: string;
    uploadHorizontalImage: string;
    seoTitle: string;
    seoKeyword: string;
    seoDescription: string;
    contentType: string;
    ingestionType: string;
    assetType: string;
    vodOrLivePosterImage: string;
    vodAssetTitle: string;
    vodClosedCaption: any;
    vodIsDrm: any;
    vodAssetDesc: string;
    vodOrLiveAdServer: any;
    vodPreRollAds: any;
    vodPostRollAds: 0;
    copyrightText: string;
    liveType: string;
    liveVirtualChannelId: any;
    liveEventId: any;
    epgUrl: string;
    hlsUrl: string;
    lyrics: string;
    duration: any;
    certificationMappedCount: any;
    bookmarkDuration: any;
    ratingCount: any;
    totalRating: any;
    userTotalRating: any;
    likeCount: any;
    isUserLikes: any;
    watchedCount: any;
    isUserWatched: any;
    primaryNavigationMappedCount: any;
    menuCategoryMappedCount: any;
    genreMappedCount: any;
    assetGroupMappedCount: any;
    averageRating: any;
    certification: any;
    primaryNavigation: PrimaryNavigation[];
    menuCategory: MenuCategory[];
    assetGroup: [];
    csaiAdServer: string;
    genre: DataGenre[];
    playbackUrl: string;
    geoStatus: boolean;
    isPremium: boolean;
    casUserWatchAsset: boolean;

}

export class AssetGroupClassification {
    path: string;
    name: string;
}

export class AssetGroupDetail {
    status: boolean;
    is_favourite: boolean;
    type: string;
    path: string;
    same_for_all_geo_zone: boolean;
    exclusion_text: string;
    product_type: ProductType[];
    same_for_all_product_type: boolean;
    same_for_all_device_type: boolean;
    asset_group_name: string;
    air_date: string;
    end_date: string;
    content_type: string;
    asset_group_type: string;
    data: AssetGroupDataObject;
    subscription_package_type: string;
    carousal: string;
    page_category: [];
    genre: Genre[];

}

export class ProductType {
    name: string;
}

export class AssetGroupDataObject {
    device: string;
    same_for_all_orientation: boolean;
    asset_count: number;
    asset_category_count: number;
    asset_category_listing: AssetCategoryListing[];
    orientation: Orientation[];
    order_by: string;
    sorting: string;
}

export class AssetCategoryListing {
    path: string;
    type: string;
    alias: string;
    title: string;
    description: string;
}

export class AssetCategoryDetail {
    path: any;
    title: string;
    description: string;
    isActive: any;
    contentType: string;
    exclusionText: string;
    sameForAllZone: any;
    sameForAllProductType: any;
    airStartDate: string;
    airEndDate: string;
    seoTitle: string;
    seoKeyword: string;
    seoDescription: string;
    deviceName: string;
    verticalFileName: string;
    verticalFilePath: string;
    horizontalFileName: string;
    horizontalFilePath: string;
    langaugeId: any;
    langaugeName: string;
    langaugeCode: string;
    dtypeId: any;
    langId: any;
    label: string;
    uploadVerticalImage: string;
    uploadHorizontalImage: string;
    isVisible: any;
    isCarouselApplicable: any;
    orderBy: string;
    sorting: string;
    type: string;
}

export class AssetCategoryData {
    device: string;
    same_for_all_orientation: boolean;
    orientation: Orientation[];
    order_by: string;
    sorting: string;
}

export class AssetGroupListing {
    success: boolean;
    data: AssetGroupListData[];
}

export class AssetGroupListData {
    is_user_watched: boolean;
    title: string;
    path: string;
    type: string;
    created: string;
    alias: string;
    asset_group_classification: [];
    duration: string;
    air_date: string;
    end_date: string;
    average_rating: string;
    likes_count: number;
    watched_count: number;
    genre: Genre[];
    device: string;
    same_for_all_orientation: boolean;
    orientation: Orientation[];
    order_by: string;
    sorting: string;
    display_indexing: string;
    user_can_see_asset: string;
    asset_is_premium: string;
}