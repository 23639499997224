<div class="top-header wrapperbox">
    <div class="container">
        <div class="wrapperbox" *ngIf="!noDataFound"><nav>
            <ol class="breadcrumb" *ngIf=" videopageData != undefined && videopageData.label != undefined">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Session</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">{{selectedLanguage}}</a></li>
                <li class="breadcrumb-item active" *ngIf=" videopageData != undefined && videopageData.label != undefined">{{videopageData.label}}</li>
            </ol>
        </nav></div>
        <ng-container *ngIf="!watchMovie && !noDataFound">
            <div (click)="playMovie()" class="">
                <div class="novid_play">                
                    <img [src]="videoPoster"  width=" 100%" height="100%" alt="video banner" />
                    <div class="playbtnw"></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="noDataFound">
            <div  class="">
                <div class="novid_play">                
                    <img [src]="seriesImage"  width=" 100%" height="100%" alt="video banner"  (error)="setDefaultPic($event)"/>                    
                </div>
            </div>
        </ng-container>
        <div class="mainPlayer">
            <div class=" rel_pos">
                <ng-container *ngIf="watchMovie">
                    <div class="bitmovin_container video_details">
                        <div id="bitmovin-player"> </div>
                        <div class="play_forword_backward_button">
                            <span class="seek_backword_icon" title="" (click)="seekPlayer(-10, false)"><svg _ngcontent-gka-c5="" height="48.235" viewBox="0 0 48.235 48.235" width="48.235" xmlns="http://www.w3.org/2000/svg"><g _ngcontent-gka-c5="" transform="translate(-9621.999 -4634)"><g _ngcontent-gka-c5="" transform="translate(9621.999 4656.627) rotate(-45)"><g _ngcontent-gka-c5="" transform="translate(0 0)"><path _ngcontent-gka-c5="" d="M32,20.216a16,16,0,1,1-32,0,.949.949,0,0,1,1.9,0A14.1,14.1,0,1,0,16,6.114H14.353L16.708,8.75a.949.949,0,1,1-1.415,1.264L11.927,6.247a1.623,1.623,0,0,1,0-2.163L15.292.317a.949.949,0,1,1,1.415,1.264L14.354,4.216H16A16.018,16.018,0,0,1,32,20.216Z" fill="#fff"></path></g></g><text _ngcontent-gka-c5="" fill="#fff" font-family="Montserrat-SemiBold, Montserrat" font-size="14" font-weight="600" transform="translate(9641 4665)"><tspan _ngcontent-gka-c5="" x="0" y="0">10</tspan></text></g></svg></span>
                            <span class="seek_forword_icon" (click)="seekPlayer(+10, false)"> <svg _ngcontent-gka-c5="" height="48.236" viewBox="0 0 48.235 48.236" width="48.235" xmlns="http://www.w3.org/2000/svg"><g _ngcontent-gka-c5="" transform="translate(-9665 -4634)"><g _ngcontent-gka-c5="" transform="translate(9708.702 4703.311) rotate(-135)"><g _ngcontent-gka-c5="" transform="translate(29.805)"><path _ngcontent-gka-c5="" d="M61.8,16a16,16,0,1,0-32,0,.949.949,0,0,0,1.9,0A14.1,14.1,0,1,1,45.8,30.1H44.158l2.354-2.635A.949.949,0,1,0,45.1,26.2l-3.365,3.767a1.623,1.623,0,0,0,0,2.163L45.1,35.9a.949.949,0,1,0,1.415-1.264L44.158,32H45.8A16.018,16.018,0,0,0,61.8,16Z" fill="#fff" transform="translate(-29.805)"></path></g></g><text _ngcontent-gka-c5="" fill="#fff" font-family="Montserrat-SemiBold, Montserrat" font-size="14" font-weight="600" transform="translate(9680 4665)"><tspan _ngcontent-gka-c5="" x="0" y="0">10</tspan></text></g></svg></span>
                        </div>
                    </div>
                </ng-container>
                
                

            </div>               
        </div>
        <div class="wrapperbox"><h2 class="weight-100 mt-3" *ngIf=" videopageData != undefined && videopageData.label != undefined">{{videopageData.label}}</h2></div>
        <div class="wrapperbox"><h2 class="weight-100 mt-3" *ngIf=" videopageData == undefined && this.assetGroupData != undefined && noDataFound">{{this.assetGroupData.data[0].label}}</h2></div>
        <div class="wrapperbox">
            <div class="row">
                <div class="col-sm-6"><nav class="nav text-white">
                    <a href="javascript:void(0)"  *ngIf="isUserLikes == 0" (click)="addToFavourite()" class="nav-item nav-link"><i class="far fa-thumbs-up mr-1"></i> <span></span></a>
                    <a href="javascript:void(0)"  *ngIf="isUserLikes == 1" (click)="removeFromFav()" class="nav-item nav-link"><i class="fa fa-thumbs-up mr-1"></i> <span></span></a>
                    <a href="javascript:void(0)" class="nav-item nav-link" *ngIf=" videopageData != undefined && videopageData.watchedCount != undefined"><i class="far fa-eye  mr-1"></i> <span >{{videopageData.watchedCount}}</span></a>
                    
                    <a class="nav-item nav-link" *ngIf="videopageData != undefined" (click)="openSharePopup()">
                        <img  src="{{ Global.IMAGE_PREFIX }}share.svg " alt="Share" title="Share" />
                        {{ pdTxtShare }}
                    </a>
                    
                </nav></div>
                <div class="col-sm-6"><div class=" video-info-block d-flex  m-0" >
                    <div *ngIf="videoTitle" class="video-detail statistic">
                        <div class="likes" (click)="addToFavourite()" *ngIf="isUserLoggedIn && !assetAdded">
                            <i class="fa fa-heart-o" title="Add to Favourites" aria-hidden="true"></i>
                        </div>
    
                        <div class="likes" style="color:#ee8125;" (click)="removeFromFav()" *ngIf="assetAdded">
                            <i class="fa fa-heart" title="Add to Favourite" aria-hidden="true"></i>
                        </div>
    
                        <div class="share d-flex" (click)="openSharePopup()">
                            <img class="mr-1" src="{{ Global.IMAGE_PREFIX }}share.svg " alt="Share" title="Share" />
                            {{ pdTxtShare }}
                        </div>
    
                    </div>
                    
                    <div class="video-title trailor-btn ms-auto">
    
                        <div class="vid-title">
                            {{ videoTitle }}
                        </div>
                        <div class="right_btns">
                            <button *ngIf="isTrailerBtn" (click)="playTrailer()" class="btn watch-list-btn">
                                <span>{{ watchTrailer }}</span>
                            </button>
                            <button *ngIf="isVideoBtn" [disabled]="planExpired" (click)="playMovie()" class="btn watch-list-btn">
                                <span>{{ pdTxtWatchEpisode }}</span>
                            </button>
                        </div>
                    </div>
                    
                    
                </div></div>
            </div>
            
            
            
        </div>
        <div class="wrapperbox d-flex">
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" *ngIf=" videopageData != undefined && videopageData.label != undefined"><a href="javascript:void(0)">{{videopageData.label}}</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)" *ngIf="videopageData !=undefined &&  videopageData.airStartDate != undefined &&  videopageData.airStartDate != '0000-00-00 00:00:00'">{{videopageData.airStartDate
                        | date: 'yyyy'}}</a></li>
                    <li class="breadcrumb-item active" *ngIf="videopageData != undefined && videopageData.label != undefined">{{selectedLanguage}}</li>
                </ol>
            </nav>
            <div class="detail ml-3">
                <div class="genre" *ngIf="videopageData && videopageData?.genre?.length > 0">
                    <span *ngIf="movieYear">
                        {{movieYear | date: 'yyyy'}}
                    </span>
                    <span *ngFor="let genre of videopageData.genre; let i = index;">
                        <span>
                            {{' | ' + genre.genreName }}
                        </span>
                        <span *ngIf="videopageData && (videopageData?.genre?.length - 1 != i)">, </span>
                    </span>
                </div>
                <div class="description mt-20" [innerHtml]="videoDescription">                               
                </div>                            
            </div>
        </div>
        <div class="wrapperbox d-flex" *ngIf="cast != null && cast != undefined && cast != ''">
            <p class="p-0 mt-0 mb-0 mr-1 weight-500">Cast :</p>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{cast}}</a></li>                    
                </ol>
            </nav>
        </div>
        <div class="wrapperbox d-flex" *ngIf="director != null && director != undefined && director != ''">
            <p class="p-0 mt-0 mb-0 mr-1 weight-500">Director :</p>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{director}}</a></li>                   
                </ol>
            </nav>
            </div>
        <div class="wrapperbox" *ngIf="videopageData != undefined && videopageData.description != undefined">
            <h3 class="weight-100 mt-0">Detail</h3>
            <p [innerHTML]=videopageData.description></p>
            
        </div>
        <div class="wrapperbox mt-2 mb-2 seasons-episode">
            <h3 class="weight-100" *ngIf="categoryList != undefined">{{pdTxtSeasons}}</h3>
            <nav class="nav" *ngIf="categoryList != undefined">
                <li class="nav-item nav-link" *ngFor="let seasonList of categoryList" [ngClass]="{'activeSeasons': seasonList.path == seasonPath}"
                    onchange="getAssetCategoryDetails(seasonList.path,  true)"
                    (click)="getAssetCategoryDetails(seasonList.path,  true )"><a
                        href="javascript:void(0)">{{seasonList.title}}</a></li>
            </nav>          

        </div>
        <div class="wrapperbox mt-5"  *ngIf="assetGroupList != undefined">
            
            <div class="row" >
                <div class="col-sm-3 mb-4" *ngFor="let dataList of assetGroupList.data; let i = index" (click)="getVideoDetails(dataList.path, i)">
                    <div class="wrapperbox bg-blackdark">
                        <div class="wrapperbox">
                            <img src="{{dataList.horizontalFilePath}}" *ngIf="dataList.horizontalFilePath != '' && dataList.horizontalFilePath != null" class="img-fluid" alt="Responsive image" (error)="setDefaultPic($event)">
                            
                           </div>
                        <div class="wrapperbox p-2">
                            <h5 class="weight-100 text-white">{{dataList.label}}</h5>
                            <div class="wrapperbox">
                                
                                <div class="like-view  d-flex">
                                    <!-- <div class="time d-flex">04:38 min</div> -->
                                    <nav class="nav ms-auto">
                                        <a href="javascript:void(0)" class="nav-item nav-link p-0 mr-2"><i class="far fa-thumbs-up mr-1"></i> <span></span></a>
                                        <a href="javascript:void(0)" class="nav-item nav-link p-0"><i class="far fa-eye  mr-1"></i> <span>{{dataList.watchedCount}}</span></a>
                                    </nav>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        <div class="wrapperbox mt-5"  *ngIf="assetGroupList == undefined && noDataFound">
            <h3>{{pdTxtNoDataFound}}</h3>
        </div>
        
        
    </div>


    <div id="js_close_logout_popup" *ngIf="showPremiumPopup" class="js_close_logout_popup" [ngClass]="'popupoverlay'">
        <div class="js_logoutPopup logoutPopup">
            <span class="close_popup" (click)="closePopup()">
                <i class="fa fa-close"></i>
            </span>
    
            <span class="logoutText text-center">This is a premium movie please subscribe to watch</span><br/>
            <div class="logoutSet">
                <a (click)="closePopup()" href="javascript:void(0);" class="logoutButton logYes">Cancel</a>
                <a (click)="subscriptionPopup()" class="logoutButton logYes">Go To Plans</a>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    
    <div id="js_close_logout_popup" *ngIf="showSubscriptionPopup" class="js_close_logout_popup" [ngClass]="'popupoverlay'">
        <div class="js_logoutPopup logoutPopup">
            <span class="close_popup" (click)="closePopup()">
                <i class="fa fa-close"></i>
            </span>
            <span class="logoutText text-center"> {{pdPleaseLogintoSubscribe}} </span>
            <br/>
            <div class="logoutSet">
                <a (click)="closePopup()" href="javascript:void(0);" class="logoutButton logYes">{{pdTxtCancel}}</a>
                <a (click)="subscriptionPopup()" class="logoutButton logYes">{{pdtxtSubscribe}}</a>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    
    <div id="js_close_logout_popup" *ngIf="showLoginPopup" class="js_close_logout_popup" [ngClass]="'popupoverlay'">
        <div class="js_logoutPopup logoutPopup">
            <span class="close_popup" (click)="closePopup()">
                <i class="fa fa-close"></i>
            </span>
            <span class="logoutText text-center">{{ pdPleaseLogin }}</span><br/>
            <div class="logoutSet">
                <a (click)="closePopup()" href="javascript:void(0);" class="logoutButton logYes">{{ pdTxtCancel }}</a>
                <a (click)="signInPopup()" class="logoutButton logYes">{{ pdTxtOk }}</a>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>

<div id="js_close_logout_popup" *ngIf="showSharePopup" class="js_close_logout_popup link" [ngClass]="'popupoverlay'">
    <div class="js_logoutPopup logoutPopup">
        <span class="close_popup" (click)="closePopup()">
            <i class="fa fa-close"></i>
        </span>
        <span class="logoutText text-center">Share on</span>
        <br/>
        <button shareButton="facebook" [url]="shareUrl" #fbBtn="shareButton"
                [style.backgroundColor]="fbBtn?.color">
            <i class="fa fa-facebook" aria-hidden="true"></i>
        
        </button>
        
        <button shareButton="twitter" [url]="shareUrl" #twtBtn="shareButton"
                [style.backgroundColor]="twtBtn?.color">
            <i class="fa fa-twitter" aria-hidden="true"></i>    
        </button>
        <button shareButton="whatsapp" [url]="shareUrl" #waBtn="shareButton"
                [style.backgroundColor]="waBtn?.color">
            <i class="fa fa-whatsapp" aria-hidden="true"></i>
        </button>

        <div class="clearfix"></div>
    </div>

</div>
 <!-- onit loader  -->  
 <div class="main-loader-overlay light-bg" style="display: block;" *ngIf="tabLoading == true">
    <i class="fa fa-spinner fa-spin"></i>
  </div>
<!-- end onit loader  -->