import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Constants, CastCrews, VideoPageDataObject, MESSAGES_EN, MESSAGES_HI, 
  AppCoreService,  PaymentService,AssetService, AppTokenService,PageCategory } from './index';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { ShareModule } from 'ngx-sharebuttons';

declare var $: any;
declare const bitmovin: any;

@Component({
  selector: 'app-asset-group-details',
  templateUrl: './asset-group-details.component.html',
  styleUrls: ['./asset-group-details.component.scss']
})

export class AssetGroupDetailsComponent implements OnInit {
  currentLanguage = 'en';
  pdTxtWatchList: any = '';
  pdTxtDuration: any = '';
  pdMsgNoRecordFounds: any = '';
  showBitmovinPlayer = true;
  pdTxtAssetRemovedFav: any = '';
  pdTxtAssetAlreadyAddedFav: any = '';
  pdTxtPleaseLogin: any = '';
  pdTxtAssetAddedFav: any = '';
  pdTxtSuggestedVideo: any = '';
  pdTxtRelatedVideo: any = '';
  pdTxtActors: any = '';
  pdTxtDetail: any = '';
  watchTrailer: any;
  watchEpisode:any;
  watchMovie = false;
  starCast: any = '';
  directorName: string;
  watchNow: any;
  pdTxtWatchMovie: string;
  pdTxtWatchEpisode: string;
  pdTxtSeasons: string;
  pdTxtNoDataFound: string;
  pdTxtYes: any = '';
  pdTxtOk: any = '';
  pdtxtSubscribe: any = '';
  pdTxtShare: any = '';
  pdTxtCancel: any = '';
  pdPleaseLogin: any = '';
  pdPleaseLogintoSubscribe: any = '';
  _urlParams: any;
  _assetID: string;
  trailer_playback_url = '';
  addedToWatchList = false;
  videopageData: VideoPageDataObject;
  videoURL: string;
  videoPoster: string;
  bookmark_duration: any;
  trailerPlayBackUrl: string;
  isUserLikes: any;
  isPremium: boolean;
  casUserWatchAsset: boolean;
  csaiAdServer: string;
  same_for_all_geo_zone = false;
  showButton: boolean;
  geoStatus = false;
  genrepath: any = [];
  primaryNavigationpath: any = [];
  menuCategorypath: any = [];
  relatedVideoData: any;
  castcrewData: CastCrews;
  director: string;
  cast: string;
  genreTitle = '';
  noGenre = false;
  user: boolean;
  isShowVideo = false;
  isShowImage = false;
  setBookMark = false;
  showVideoError = false;
  isIncreaseCount = false;
  bitmovinPlayer: any;
  subscriptionData = [];
  rented: boolean;
  showTrailerWithoutPayment = false;
  clickOntrailer = false;
  watchListTimeBitmovin = 5;
  bitMovieUnsubsribe: any;
  playTimeOut: any;
  isUserLoggedIn = false;
  isTrailer = false;
  playerVisible = '';
  movieYear = '';
  isShowStarCastSection = false;
  castCrew: any;
  suggestedVideosList: any;
  videoTitle: any;
  showPoster = true;
  planExpired = false;
  assetAdded = false;
  videoDescription = '';
  validityExpired = false;
  showLoginPopup = false;
  showPremiumPopup = false;
  showSubscriptionPopup = false;
  showSharePopup = false;
  shareUrl = '';
  defaultImg: string; 
  container;
  player;
  adultContent: string;
  allowToWatchPremiumContent = false;
  isTrailerBtn: string | boolean;
  isVideoBtn;
  videoduration: any;
  emailmobile: any;
  userID: any;
  assetGroupData: any;
  assetgroupSubscriptionId: any;
  assetgeoStatus: any;
  currentSeasonPath: string;
  currentIndex: number;
  seconds: number;
  assetGroupList: any;
  seasonPath: string;
  assetCategoryData: any;
  skip: number;
  loading: boolean;
  viewMore: boolean;
  redirectsearchUrl: any;
  redirectfavouriteUrl: any;
  redirectwatchListUrl: any;
  redirectpagecategoryUrl: any;
  mainloading: boolean;
  categoryList: any;
  lastVideo: boolean;
  videoFinish: boolean;
  videoPath: string;
  playerObject: any;
  posterImage: string;
  currentpath: any;
  playerDuration: any;
  smalldescription: string;
  showKnowMore: boolean;
  isVideoPlay: boolean;
  tvodRate: any;
  tvodasset: boolean;
  tvodDays: any;
  isSVOD: boolean;
  svodStatus: boolean;
  selectedLanguage : string;
  watchedCount: any;
  noDataFound: boolean = false;
  seriesImage:any="";
  shareModule :ShareModule;
  tabLoading:boolean = false;

  constructor(
    private appCoreService: AppCoreService,
    private appTokenService: AppTokenService,
    private assetService: AssetService,
    private paymentService: PaymentService,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public Global: Constants
  ) {
    this.defaultImg = this.Global.defaultPageCategoryImgPath;
  }

  ngOnInit(): void {
    this.emailmobile = this.appCoreService.GetUserEmailMobile();
    const Language = this.appCoreService.GetLanguage();
    const selectedLanguage = this.appCoreService.GetLanguage();
    this.noDataFound = false;
    this.appCoreService.setSetNavigationActive("");
    this.appCoreService.CheckLanguageSelected().subscribe(lang => {
      if (lang !== undefined) {
        if (this.currentLanguage == 'en'){
          this.selectedLanguage = 'Eng';
        }
        if (this.currentLanguage == 'hi'){
          this.selectedLanguage = 'हिंदी';
        }
        this.appTokenService.CheckToken().subscribe(token => {
          if (token !== undefined && token) {
            //console.log('inside check token');
            // bind user login
            this.appCoreService.CheckUserLoggedIn().subscribe(res => {
              if (res !== undefined) {
                this.isUserLoggedIn = this.appCoreService.isUserLoggedIn.value;
                //console.log('isUserLoggedIn -> ', this.isUserLoggedIn);
              
                if (this.isUserLoggedIn) {
                  const uid = this.appCoreService.GetUserData().userId;
                  const requestParameters = {
                    userId: uid
                  };
                  const requestObject = '?filter=' + encodeURI(JSON.stringify(requestParameters));
                  this.paymentService.CheckPlanValidity(requestObject).subscribe(response => {
                    if (response !== undefined) {
                      //console.log('Plan Validity Response -> ', response);
                      if (response.data.status !== false) {
                        if (!response.data[0].isActivePlan) {
                          this.validityExpired = true;
                        } else {
                          // get some details if needed
                          if (response.data[0].usageType === 'paid') {
                            this.allowToWatchPremiumContent = true;
                          //  this.track_moeEvent('paid_user', response.data[0].usageType , null, null, null);
                          }
                        }
                      } else {
                        this.validityExpired = true;
                      }
                      this.userID = this.appCoreService.GetUserData().userId;
                      this.track_moeEvent('free_user', response.data[0].usageType , null, null, null,this.emailmobile,this.userID, this._assetID);
                    }
                  });

                } else {

                }
              }
            });

            this._activatedRoute.params.subscribe(params => {
              this.addedToWatchList = false; // reset value when activate route
              const param_path = 'path';
              this._urlParams = params;
              this._assetID = this._urlParams[param_path];
              this.setMessages();
              // this.getGuestToken();
              this.casUserWatchAsset = false;
              this.getAssetGroupDetails(this._assetID);

              if (this.isUserLoggedIn) {

                this.getFavorites();
              }
            });

          } else if (!token) {
            // need to generate a token first
            this.appTokenService.CreateToken().subscribe(new_token => {
              if (new_token !== undefined) {
                this.appTokenService.SetToken(new_token);
              }
            });
          }
        });       
      }
    });
  }





  setMessages() : void {
    // set the language
    this.currentLanguage = this.appCoreService.GetLanguage();
    this.pdTxtAssetRemovedFav = MESSAGES_EN.txtAssetRemovedFav;
    this.pdTxtAssetAlreadyAddedFav = MESSAGES_EN.txtAssetAlreadyAddedFav;
    this.pdTxtPleaseLogin = MESSAGES_EN.txtPleaseLogin;
    this.pdTxtAssetAddedFav = MESSAGES_EN.txtAssetAddedFav;
    this.pdTxtSuggestedVideo = MESSAGES_EN.txtSuggestedVideo;
    this.pdTxtRelatedVideo = MESSAGES_EN.txtRelatedVideo;
    this.pdMsgNoRecordFounds = MESSAGES_EN.msgNoRecordFounds;
    this.pdTxtActors = MESSAGES_EN.txtActors;
    this.pdTxtDetail = MESSAGES_EN.txtDetail;
    this.watchTrailer = MESSAGES_EN.watchTrailer;
    this.pdTxtWatchMovie = MESSAGES_EN.watchMovie;
    this.pdTxtWatchEpisode = MESSAGES_EN.watchEpisode;
    this.pdTxtSeasons = MESSAGES_EN.seasons;
    this.pdTxtNoDataFound = MESSAGES_EN.msgNoDataFound
    this.starCast = MESSAGES_EN.starCast;
    this.directorName = MESSAGES_EN.directorName;
    this.watchNow = MESSAGES_EN.watchNow;
    this.pdTxtCancel = MESSAGES_EN.txtCancel;
    this.pdTxtYes = MESSAGES_EN.txtYes;
    this.pdTxtOk = MESSAGES_EN.txtok;
    this.pdtxtSubscribe = MESSAGES_EN.txtSubscribe;
    this.pdPleaseLogin = MESSAGES_EN.txtPleaseLogin;
    this.pdPleaseLogintoSubscribe = MESSAGES_EN.PleaseLogintoSubscribe;
    this.pdTxtShare = MESSAGES_EN.txtShare;

    if (this.currentLanguage === this.Global.LANG_HI) {
      this.pdTxtShare = MESSAGES_HI.txtShare;
      this.pdTxtCancel = MESSAGES_HI.txtCancel;
      this.pdTxtYes = MESSAGES_HI.txtYes;
      this.pdTxtOk = MESSAGES_HI.txtok;
      this.pdPleaseLogin = MESSAGES_HI.txtPleaseLogin;
      this.pdPleaseLogintoSubscribe = MESSAGES_HI.PleaseLogintoSubscribe;
      this.pdtxtSubscribe = MESSAGES_HI.txtSubscribe;
      this.pdTxtAssetRemovedFav = MESSAGES_HI.txtAssetRemovedFav;
      this.pdTxtAssetAlreadyAddedFav = MESSAGES_HI.txtAssetAlreadyAddedFav;
      this.pdTxtPleaseLogin = MESSAGES_HI.txtPleaseLogin;
      this.pdTxtAssetAddedFav = MESSAGES_HI.txtAssetAddedFav;
      this.pdTxtSuggestedVideo = MESSAGES_HI.txtSuggestedVideo;
      this.pdTxtRelatedVideo = MESSAGES_HI.txtRelatedVideo;
      this.pdMsgNoRecordFounds = MESSAGES_HI.msgNoRecordFounds;
      this.pdTxtActors = MESSAGES_HI.txtActors;
      this.pdTxtDetail = MESSAGES_HI.txtDetail;
      this.watchTrailer = MESSAGES_HI.watchTrailer;
      this.pdTxtWatchMovie = MESSAGES_HI.watchMovie;
      this.pdTxtWatchEpisode = MESSAGES_HI.watchEpisode;
      this.pdTxtSeasons = MESSAGES_HI.seasons;
      this.pdTxtNoDataFound = MESSAGES_HI.msgNoDataFound;
      this.starCast = MESSAGES_HI.starCast;
      this.directorName = MESSAGES_HI.directorName;
      this.watchNow = MESSAGES_HI.watchNow;
    }
 }

 public getAssetGroupDetails(path: string): void {

  const assetGroupDetailRequest = {
    "path": path,
    "langId": this.currentLanguage === this.Global.LANG_HI ? 2 : 1  
  }
  let genreFiterString = "?filter=" + encodeURI(JSON.stringify(assetGroupDetailRequest));
  this.assetService.GetAssetGroupDetails(genreFiterString).subscribe(res => {
    if (res !== undefined && res.success == true) {
      this.assetGroupData = res;
      this.seriesImage = this.assetGroupData.data[0].horizontalFilePath == null ? this.assetGroupData.data[0].verticalFilePath: this.assetGroupData.data[0].horizontalFilePath;
      this.assetgeoStatus = this.assetGroupData.data[0].geoStatus;
      if (localStorage.getItem('seasonPath') !== null) {
        this.currentSeasonPath = localStorage.getItem('seasonPath').toString();
        localStorage.removeItem('seasonPath');
      } else {
        this.currentSeasonPath = this.assetGroupData.data[0].assetCategory[0].path.toString();
      }
      this.getAssetCategoryDetails(this.currentSeasonPath, true);
    }
  },
    err => {
      console.log(err);
    });
}

seekPlayer(duration, videoDuration: boolean, setBookMark = true): void {
  if (videoDuration) {
    if (setBookMark) {
      if ((this.bitmovinPlayer.getDuration() - this.bookmark_duration) > 30) {
        this.bitmovinPlayer.seek(this.bitmovinPlayer.getCurrentTime() + this.bookmark_duration);
      } else {
        this.bitmovinPlayer.seek(this.bitmovinPlayer.getCurrentTime() + duration);
      }
    }
  } else {
    this.bitmovinPlayer.seek(this.bitmovinPlayer.getCurrentTime() + duration);
  }
}

public getAssetCategoryDetails(assetPath: string, startvideo): void {
  this.tabLoading = true;
  this.noDataFound = false;
  this.currentIndex = 0;
  this.seconds = 5;
  this.assetGroupList = undefined;
  this.clickOntrailer = false;  
  this.seasonPath = assetPath;
  const assetCategoryDetailRequest = {
    "path": assetPath,
    "langId": this.currentLanguage === this.Global.LANG_HI ? 2 : 1  
  }
  let assetCategoryFilterString = "?filter=" + encodeURI(JSON.stringify(assetCategoryDetailRequest));
  this.assetService.GetAssetCategory(assetCategoryFilterString).subscribe(res => {
    if (res !== undefined && res.success === true) {
      this.assetCategoryData = res.data[0];
      this.skip = 0;
      this.getAssetGroupListing(0, this.assetCategoryData, false, startvideo);
    }
  },
    err => {
      console.log(err);
      this.tabLoading = false;
    });
}

public getAssetGroupListing(skip: number, assetCategory: PageCategory, setData: boolean, startVideo): void {
  this.clickOntrailer = false;
  const assetGroupListingRequest = {
    "path": assetCategory.path,
    "type": "asset_category",
    "deviceTypeId": "1",
    "langId": this.currentLanguage === this.Global.LANG_HI ? 2 : 1  
  }
  this.skip = this.skip + skip;
  if (setData) {
    this.loading = true;
  }
  let assetGroupListingString = "?filter=" + encodeURI(JSON.stringify(assetGroupListingRequest)) + "&start=" + this.skip + "&limit=24";
  this.assetService.GetAssetListing(assetGroupListingString).subscribe(res => {
    if (res !== undefined && res.success === true) {
      if (res.data.length > 0){
      if (res.data.length == 24) {
        this.viewMore = true;
      } else {
        this.viewMore = false;
      }
      if (setData) {
        res.data.forEach(record => {
          this.assetGroupList.data.push(record);
        });
        this.loading = false;

      } else {
        this.assetGroupList = res;
        if (startVideo != false) {        
          if(this.assetGroupList.data.length > 0 && (this.redirectsearchUrl == null || this.redirectsearchUrl == undefined) && (this.redirectfavouriteUrl == null || this.redirectfavouriteUrl == undefined) && (this.redirectwatchListUrl == null || this.redirectwatchListUrl == undefined) && (this.redirectpagecategoryUrl == null || this.redirectpagecategoryUrl == undefined)){
            this.getVideoDetails(this.assetGroupList.data[0].path, this.currentIndex);
          }
          else if( this.assetGroupList.data.length > 0 && (this.redirectsearchUrl != null && this.redirectsearchUrl != 'undefined'))
          {
            console.log("searchUrl", this.redirectsearchUrl);
            this.getVideoDetails(this.redirectsearchUrl,0);
            localStorage.removeItem('searchUrl');
            this.redirectsearchUrl = null;
          }
          else if(this.assetGroupList.data.length > 0 && this.redirectfavouriteUrl != null || this.redirectfavouriteUrl != undefined)
          {
            console.log("redirectfavouriteUrl", this.redirectfavouriteUrl);
            this.getVideoDetails(this.redirectfavouriteUrl,0);
            this.redirectfavouriteUrl = null;
            localStorage.removeItem('favouriteUrl');
          }
          else if( this.assetGroupList.data.length > 0 && this.redirectwatchListUrl != null || this.redirectwatchListUrl != undefined)
          {
            console.log("watchlist", this.redirectwatchListUrl);
            this.getVideoDetails(this.redirectwatchListUrl,0);
            localStorage.removeItem('watchListUrl');
            this.redirectwatchListUrl = null;
          }
          else if( this.assetGroupList.data.length > 0 && this.redirectpagecategoryUrl != null || this.redirectpagecategoryUrl != undefined)
          {
            console.log("pagecategoryUrl", this.redirectpagecategoryUrl);
            this.getVideoDetails(this.redirectpagecategoryUrl,0);
            localStorage.removeItem('pagecategoryUrl');
            this.redirectpagecategoryUrl = null;
          }
          this.loading = false;
          this.tabLoading= false;
        }
      }
      console.log('this.assetGroupList.data==>', this.assetGroupList.data);
      if (this.assetGroupList.data.length <= 0 ) {
        let index = this.assetGroupData.data[0].assetCategory.findIndex(x => x.path !== assetCategory.path);
        this.assetGroupData.data[0].assetCategory.splice(index - 1, 1);
        this.mainloading = false;
        this.getAssetCategoryDetails(this.assetGroupData.data[0].assetCategory[0].path, true);

      }
      this.categoryList = this.assetGroupData.data[0].assetCategory;
    } else {
      if (this.seriesImage == ""){
        this.seriesImage = this.defaultImg;
      }
      this.tabLoading = false;
      this.isTrailer = false;
      this.isTrailerBtn = false;
      this.noDataFound = true;
      this.videoURL = undefined;
      this.posterImage = undefined;
      this.videoPoster = undefined;
      this.trailerPlayBackUrl = undefined;
      this.isUserLikes = undefined;
      this.isVideoBtn = false;
      this.videopageData = undefined;
      this.director = undefined;
      this.cast = undefined;
      this.selectedLanguage = undefined;
    }
    }
  },
    err => {
      console.log(err);
    });
}

public getVideoDetails(videoPath: string, currenIndex): void {
  this.lastVideo = false;
  this.csaiAdServer = '';
  this.clickOntrailer = false;
  this.currentIndex = currenIndex;
  this.videoFinish = false;
  this.seconds = 5;
  if (localStorage.getItem('videoId') !== null) {
    this.videoPath = localStorage.getItem('videoId').toString();
    localStorage.removeItem('videoId');
  } else {
    this.videoPath = videoPath.toString();
  }
  
  this.getCastCrew(this.videoPath);
  
  const width = window.innerWidth;
  const height = window.innerHeight;
  const cookie = this.cookieService.get('readDisclaimer');
  var dnt = 0;
  if (cookie == 'true') {
    dnt = 1;
  } else {
    dnt = 0;
  }
  const assetDetailRequest = { "deviceTypeId": "1","langId": this.currentLanguage === this.Global.LANG_HI ? 2 : 1  };
  const userAgent = window.navigator.userAgent;
  const assetDetailFiterString = "?filter=" + encodeURI(JSON.stringify(assetDetailRequest))+ '&width=' + width + '&height=' + height + '&dnt=' + dnt + '&ua=' + userAgent;
  this.assetService.GetAssetDetails(this.videoPath, assetDetailFiterString).subscribe(res => {
    if (res !== undefined && res.success === true) {
      this.videopageData = res.data[0];
      this.addedToWatchList = this.videopageData.isUserWatched;
      this.videoURL = this.videopageData.playbackUrl;
      this.posterImage = this.videopageData.horizontalFilePath;
      this.videoPoster = this.videopageData.horizontalFilePath;
      this.trailerPlayBackUrl = this.videopageData.mapTrailerVideo;
      this.isUserLikes = this.videopageData.isUserLikes;
      this.csaiAdServer = this.videopageData.csaiAdServer;
      this.isPremium = Boolean(Number(this.videopageData.isPremium));
      this.casUserWatchAsset = this.videopageData.casUserWatchAsset;
      this.currentpath = res.data[0].path;
      this.playerDuration = this.videopageData.duration;
      if (this.currentLanguage == 'en'){
        this.selectedLanguage = 'Eng';
      }
      if (this.currentLanguage == 'hi'){
        this.selectedLanguage = 'हिंदी';
      }

      let videoDescription = this.videopageData.description;
      if (videoDescription.length > 68) {
        this.smalldescription =  videoDescription.substring(0, 68 );
        this.showKnowMore = true;
      } else {
        this.smalldescription = this.videopageData.description;
        this.showKnowMore = false;
      }
      
      // this.getshareURL();
      if (this.trailerPlayBackUrl == '') {
        this.showButton = false;
      } else if (this.trailerPlayBackUrl == null) {
        this.showButton = false;
      } else if (!this.trailerPlayBackUrl) {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
      if (this.videoURL == '') {
        this.watchMovie = false;
      } else if (this.videoURL == null) {
        this.watchMovie = false;
      } else if (!this.videoURL) {
        this.watchMovie = false;        
      } else if (this.videoURL){
        this.watchMovie = true;
        this.isVideoBtn = true;
      }
      this.bookmark_duration = this.videopageData.bookmarkDuration;
      if (this.bookmark_duration == null) {
        this.bookmark_duration = 0;
      }
      this.same_for_all_geo_zone = false;
      if (!this.assetgeoStatus){
        this.geoStatus = this.assetgeoStatus;
      }else {
      this.geoStatus = this.videopageData.geoStatus;
      }

      this.loading = false;
      this.mainloading = false;

      if (this.trailerPlayBackUrl === '') {
        this.showButton = false;
      } else if (this.trailerPlayBackUrl === null) {
        this.showButton = false;
      } else if (!this.trailerPlayBackUrl) {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
      this.isTrailer = this.videopageData.assetType === 'Trailer' ? true : false;
      this.isPremium = this.videopageData.assetType === 'Premium' ? true : false;

      const { assetType, mapTrailerVideo } =  this.videopageData;      
      if((assetType === 'Asset' || assetType === 'Trailer') && mapTrailerVideo === ""){         
        this.isTrailerBtn = false;
      } else if((assetType !== null && assetType !== "") && mapTrailerVideo !== "") {   
        this.isTrailerBtn = true;
      } else if(assetType === null  && assetType === "" && mapTrailerVideo !== ""){              
        this.isTrailerBtn = true;
      } else if(assetType === null && assetType ==="" && mapTrailerVideo === "") {          
        this.isTrailerBtn = false;
      } else {
        this.isTrailerBtn = false; 
      }   


      this.same_for_all_geo_zone = this.videopageData.sameForAllZone;
      this.geoStatus = this.videopageData.geoStatus;
      if (this.isTrailer) {
        this.watchMovie = true;
        this.showVideo(this.videoURL, this.videoPoster, false, false);
      } else {
        this.watchMovie = false;
      }    

    }

  },
    err => {
      console.log(err);
    });
}

showVideo(videoUrl: string, posterImage: string, autoPlay: boolean, showTrailer): any {
  const self = this;
  if (this.isPremium === true && this.casUserWatchAsset === true) {
    this.rented = false;
  }
  if (autoPlay && this.bitmovinPlayer !== undefined) {
    this.bitmovinPlayer.pause();
  }

  var getQualityLabels = function (data) {

    if (data.height <= 162) {

      return 'Low';
    } else if (data.height <= 252) {

      return 'Medium';
    }
    else if (data.height > 700) {

      return 'High'
    }
  }

  if (this.watchMovie) {
    setTimeout(() => {
      
      const source = {
        title: this.adultContent? this.adultContent:'',
        hls: videoUrl,
        poster: posterImage,
        labeling: {
          dash: {
            qualities: getQualityLabels
          },
          hls: {
            qualities: getQualityLabels
          }
        }           
      };

      const config = {
        key: 'c205d1e6-70ff-45b7-af5a-d6084de8c6ed',
        analytics: {
          key: '26008b07-dea7-4705-b6f8-6819e1db26c7',
          videoId: this._assetID,
          title: this.videopageData.title
        },
        playback: {
          autoplay: autoPlay
        }             
      }; 

      this.container = document.getElementById('bitmovin-player');
      // console.log('source', source)
      this.player = new bitmovin.player.Player(this.container, config);
      const onTimeChanged = (data): any => {
        const currPlaybackTime = self.bitmovinPlayer.getCurrentTime();
        if (self.setBookMark) {
          addToBookmarkOnTimeUpdateJavascript();
        }
        if (self.addedToWatchList) {
          // if ( BaseComponent.baseUser !== undefined &&
          //   Object.keys(BaseComponent.baseUser).length !== 0)
          if (this.user) {
            self.addToWatchlist();
            self.bitmovinPlayer.player.off('TimeChanged', () => { });
          }
        }
      };
      this.track_moeEvent('video_player_screen_opened', null, null, null, null, this.emailmobile,this.userID, null);

      const addToBookmarkOnTimeUpdateJavascript = () => {
        const currPlaybackTime = self.bitmovinPlayer.getCurrentTime();
        if (currPlaybackTime !== 0) {
          let diffInTime = 0;
          if (parseInt(currPlaybackTime, 0) > parseInt(self.bookmark_duration, 0)) {
            diffInTime = parseInt(currPlaybackTime, 0) - parseInt(self.bookmark_duration, 0);
          } else {
            diffInTime = parseInt(self.bookmark_duration, 0) - parseInt(currPlaybackTime, 0);
          }
          if (diffInTime >= self.watchListTimeBitmovin) {
            addToBookmarkJavascript(currPlaybackTime);
          }
        }
      };

      const onTimePaused = (data) => {
        const currPlaybackTime = self.bitmovinPlayer.getCurrentTime(); 
        addToBookmarkJavascript(currPlaybackTime);
        self.bitmovinPlayer.off('Paused', () => { });
      };

      const addToBookmarkJavascript = (current_playback_position) => {
        if (current_playback_position !== 0) {
          if (self.setBookMark) {
            // if (
            //   BaseComponent.baseUser !== undefined &&
            //   Object.keys(BaseComponent.baseUser).length !== 0
            // )
            if (this.user) {
              // const pageRequest = {
              //   assetId: self.videopageData.path,
              //   duration: current_playback_position
              // };
              // self.assetService.AddToBookmark(pageRequest).subscribe(result => {
              //   if (result !== undefined && result.success === true) {
              //     self.bookmark_duration = result.data.duration;
              //   }
              // });
            }
          }
        }

      }; 

      this.player.load(source).then(() => {
        // default quality
        this.player.getAvailableVideoQualities().map((item)=>{
            if(item.label === 'High'){               
              this.player.setVideoQuality(item.id);
            }
        })
       
          //console.log('Successfully created Bitmovin Player instance')
          self.bitmovinPlayer = this.player;
          this.player.on(bitmovin.player.PlayerEvent.Playing, () => {
          // console.log('player is playing')
          this.track_moeEvent('video_watched', this._assetID, this.videopageData.title, this.genreTitle, this.videoduration, this.emailmobile,this.userID, null);
          });
          this.player.on(bitmovin.player.PlayerEvent.Paused, onTimePaused);
          this.player.on(bitmovin.player.PlayerEvent.TimeChanged, onTimeChanged);
          // player.on(bitmovin.player.PlayerEvent.Play, onVideoPlay);
          // player.on(bitmovin.player.PlayerEvent.PlaybackFinished, onFinished);
          // player.on(bitmovin.player.PlayerEvent.Error, onError);                                    
          if (autoPlay) {             
            this.player.play();
            this.addToWatchlist();
          }
          this.track_moeEvent('attended_movie', null, this.videopageData.title,null, null, this.emailmobile,this.userID,null);
        })
        .catch((reason) => {
          console.error('Player load failed', reason);
        });       
    }, 0.8);
  }
}



 bindAsset(): void {
  const assetDetailRequest = { 
    deviceTypeId: this.Global.WEB_DEVICE_ID, 
    langId: this.currentLanguage === this.Global.LANG_HI ? 2 : 1  
  };
  const width = window.innerWidth;
  const height = window.innerHeight;
  const cookie = this.cookieService.get('readDisclaimer');
  const disclaimer_read = cookie === 'true' ? 1 : 0;
  const assetDetailFiterString = '?filter=' + encodeURI(JSON.stringify(assetDetailRequest)) + '&width=' + width + '&height=' + height + '&dnt=' + disclaimer_read;
  this.assetService.GetAssetDetails(this._assetID, assetDetailFiterString).subscribe(response => {
    if (response !== undefined && response.success === true) {
      this.videopageData = response.data[0];
      this.adultContent = this.videopageData.copyrightText;
      this.videoTitle = this.videopageData.label;
      this.videoDescription = this.videopageData.description;
      this.addedToWatchList = this.videopageData.isUserWatched;
      this.videoURL = this.videopageData.playbackUrl;
      this.videoPoster = this.videopageData.horizontalFilePath;
      this.bookmark_duration = this.videopageData.bookmarkDuration !== null ?
        this.videopageData.bookmarkDuration : 0;

      this.trailerPlayBackUrl = this.videopageData.mapTrailerVideo;
      this.isUserLikes = this.videopageData.isUserLikes;
      this.isPremium = this.videopageData.isPremium;
      this.casUserWatchAsset = this.videopageData.casUserWatchAsset;
      this.csaiAdServer = this.videopageData.csaiAdServer;
      this.movieYear = this.videopageData.airStartDate;
      this.videoduration  = this.videopageData.duration;
      this.watchedCount = this.videopageData.watchedCount;
      if (this.trailerPlayBackUrl === '') {
        this.showButton = false;
      } else if (this.trailerPlayBackUrl === null) {
        this.showButton = false;
      } else if (!this.trailerPlayBackUrl) {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
      this.isTrailer = this.videopageData.assetType === 'Trailer' ? true : false;
      this.isPremium = this.videopageData.assetType === 'Premium' ? true : false;

      const { assetType, mapTrailerVideo } =  this.videopageData;      
      if((assetType === 'Asset' || assetType === 'Trailer') && mapTrailerVideo === ""){         
        this.isTrailerBtn = false;
        this.isVideoBtn = false;
      } else if(assetType !== null || mapTrailerVideo !== "") {   
        this.isTrailerBtn = true;
        this.isVideoBtn = true;
      } else if(assetType === null && mapTrailerVideo !== ""){              
        this.isTrailerBtn = true;
        this.isVideoBtn = true;
      } else if(assetType === null && mapTrailerVideo === "") {          
        this.isTrailerBtn = false;
        this.isVideoBtn = false;
      }       

      this.same_for_all_geo_zone = this.videopageData.sameForAllZone;
      this.geoStatus = this.videopageData.geoStatus;

      // this.getRelatedSuggestedVideos();
      this.getCastCrew(this.videopageData.path);
      this.getGenreTitle(this.videopageData);

      if (this.isTrailer) {
        this.watchMovie = true;
        // this.showVideo(this.videoURL, this.videoPoster, false, false);
      } else {
        this.watchMovie = false;
      }      
    }
  });
  this.track_moeEvent('asset_detail_screen_opened', null, null, null, null, this.emailmobile,this.userID, null);
}
public getCastCrew(assetpath: string): void {
  const pageRequest = { assetId: assetpath };
  const fiterString = '?filter=' + encodeURI(JSON.stringify(pageRequest));
  this.assetService.GetCastCrews(fiterString).subscribe(
    res => {
      if (res !== undefined && res.success === true) {
        this.castcrewData = res.data;
        this.cast = '';
        this.director = '';
        if (this.castcrewData !== null && this.castcrewData.data) {
          for (const castcrew of this.castcrewData.data) {
            if (castcrew.character[0] && castcrew.character[0].professionType === 'Director') {
              if (this.director === '') {
                this.director = ' ' + this.director + castcrew.firstName;
              } else {
                this.director = this.director + ', ' + castcrew.firstName;
              }
            } else if (castcrew.character[0] && castcrew.character[0].professionType === 'Cast') {
              if (this.cast === '') {
                this.cast = ' ' + this.cast + castcrew.firstName;
              } else {
                this.cast = this.cast + ', ' + castcrew.firstName;
              }
            }
          }
        }
      }
    },
    err => {
      //console.log(err);
    }
  );
}

public getGenreTitle(videopageData: VideoPageDataObject): string {
  let genreTitle = '';
  const genres = videopageData.genre;
  if (genres.length > 0) {
    for (const orientation of genres) {
      if (genreTitle !== '') {
        genreTitle += ', ';
      }
      genreTitle += orientation.genreName;
    }
  } else {
    this.noGenre = true;
  }
  this.genreTitle = genreTitle;
  return this.genreTitle;
}

 getFavorites(): void {

  const uid = this.appCoreService.GetUserData().userId;
  const requestParameters = {
    userId: uid,
    langId: this.currentLanguage === 'hi' ? 2 : 1
  };
  const requestObject = '?filter=' + encodeURI(JSON.stringify(requestParameters));
  this.assetService.GetFavourites(requestObject).subscribe(response => {

    if (response !== undefined) {

      var lstFavourites = [];
      lstFavourites = response.data.data.filter(x => {

        if (this._assetID == x.path) {

          return true;
        }
      });

      this.assetAdded = lstFavourites.length > 0 ? true : false;
    }
  });
}

public addToFavourite(): void {
  const userId: any = this.appCoreService.GetUserData().userId;
  if (userId > 0) {
    const pageRequest = {
      userId,
      assetId: this.currentpath
    };
    this.assetService.AddToFavourite(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.isUserLikes = 1;
          this.assetAdded = true;
          this.showMessage(res.message);
        } else if (res !== undefined && res.success === false) {
          this.showMessage(res.message, 'Error');
        }
      },
      err => {
        //console.log(err);
      }
    );
  } else {
    this.showMessage('Please sign in to Add to Favourite', 'Warning');
  }
}

showMessage(msg, type = 'Success'): void {
  if (type === 'Error') {
    this.toastr.error(msg, 'Error');
  } else if (type === 'Warning') {
    this.toastr.warning(msg, 'Warning');
  } else {
    this.toastr.success(msg, 'Success');
  }
}

public removeFromFav(): void {
  const uid = this.appCoreService.GetUserData().userId;
  const requestParameters = {
    userId: uid,
    assetId: this.currentpath
  };
  this.assetService.RemoveFromFavourite(requestParameters).subscribe(res => {

    if (res !== undefined) {
      this.assetAdded = false;
      this.isUserLikes = 0;
      this.showMessage(this.currentLanguage === 'hi' ? MESSAGES_HI.txtAssetRemovedFav : MESSAGES_EN.txtAssetRemovedFav);
    }
  });
}

  track_moeEvent(event_Type: string, Id: string, video_name: string, video_genre: string, video_duration: any, emailMobile: any, userID: any, lastclickedcontent: any): void {
    if(emailMobile != undefined && emailMobile != null){
      var addMobile = emailMobile.match('^((\\+91-?)|0)?[0-9]{10}$');
    }
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // if(event_Type === 'paid_user')
    // {
    //   (window as any).Moengage.track_event(event_Type, {"plan_type": Id, "device_type": 1 });
    // }
    if(event_Type === 'free_user')
    {
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event('free_user', {"plan_type": 'Free', "device_type": 1,"user_id": userID, "email": emailMobile,"watched_movies":0,"watched_hours":0, "last_clicked_content":lastclickedcontent});
      }
      else if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event('free_user', {"plan_type": 'Free', "device_type": 1,"user_id": userID, "mobile_number": emailMobile,"watched_movies":0,"watched_hours":0, "last_clicked_content":lastclickedcontent });
      }
      else {
        (window as any).Moengage.track_event('free_user', {"plan_type": 'Free', "device_type": 1,"user_id": userID });
      }
    }
    if (event_Type === 'video_watched') {
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event('video_watched', { "video_id": Id, "video_name": video_name, "video_genre": video_genre, "video_duration": video_duration, event_Type: true, "user_id": userID, "email": emailMobile });
      }
      else if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event('video_watched', { "video_id": Id, "video_name": video_name, "video_genre": video_genre, "video_duration": video_duration, event_Type: true, "user_id": userID, "mobile_number": emailMobile });
      }
      else {
        (window as any).Moengage.track_event('video_watched', { "video_id": Id, "video_name": video_name, "video_genre": video_genre, "video_duration": video_duration, event_Type: true, "user_id": userID });
      }

    }
    if (event_Type === 'video_player_screen_opened') {
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event(event_Type, { "video_player_screen_opened": true, "email": emailMobile });
      }
      else if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event(event_Type, { "video_player_screen_opened": true, "mobile_number": emailMobile });
      }
      else {
        (window as any).Moengage.track_event(event_Type, { "video_player_screen_opened": true });
      }
    }

    if (event_Type === 'asset_detail_screen_opened') {
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event('asset_detail_screen_opened', { "asset_detail_screen_opened": true, "email": emailMobile });
      }
      else if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event('asset_detail_screen_opened', { "asset_detail_screen_opened": true, "mobile_number": emailMobile });
      }
      else {
        (window as any).Moengage.track_event('asset_detail_screen_opened', { "asset_detail_screen_opened": true });
      }
    }

    if (event_Type === 'attended_movie') {
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event(event_Type, { "attended_movie": true, "attended_movie_title": video_name, "email": emailMobile });
      }
      else if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event(event_Type, { "attended_movie": true, "attended_movie_title": video_name, "mobile_number": emailMobile });
      }
      else {
        (window as any).Moengage.track_event(event_Type, { "attended_movie": true, "attended_movie_title": video_name });
      }

    }
  }
  
  
  playMovie(): any {  
    // this.validityExpired = false; // only for test
    if (this.isUserLoggedIn) {
      //console.log(this.validityExpired);
      //console.log('this.allowToWatchPremiumContent -- ', this.allowToWatchPremiumContent);
      if (!this.allowToWatchPremiumContent && this.isPremium) {
        this.showPremiumPopup = true;
      } else if (this.validityExpired && this.isPremium) {
        this.showPremiumPopup = true;
      } else if (this.validityExpired) {
        // if validity is expired, then ask user for subscription
        this.showSubscriptionPopup = true;
      } else {
        // show the player
        this.watchMovie = true;
        this.showVideo(this.videoURL, this.videoPoster, true, false);
        // this.addToWatchlist();
      }
    } else { 
       // firstly ask user to login
      if(this.player !== undefined && this.player.isPlaying()){
        this.player.pause();
        this.showLoginPopup = true;
      } else {        
         this.showLoginPopup = true;
      }
    }
  }

  playTrailer(): any {    
    this.watchMovie = true;
    this.showVideo(this.trailerPlayBackUrl, this.videoPoster, true, false);
  }

  closePopup(): void {
    this.showLoginPopup = false;
    this.showPremiumPopup = false;
    this.showSubscriptionPopup = false;
    this.showSharePopup = false;
    if(this.player !== undefined && this.player.isPaused()){
      this.player.play();     
    }
  }

  signInPopup(): void {
    this._router.navigate(['auth/login'], { queryParams: { returnUrl: window.location.pathname } });
  }

  subscriptionPopup(): void {
    this._router.navigate(['plans'], { queryParams: { returnUrl: window.location.pathname } });
  }

  public addToWatchlist(): void {
    if (this.watchMovie === true || this.showTrailerWithoutPayment === true) {
      const uid = this.appCoreService.GetUserData().userId;
      const requestObject = {
        userId: uid,
        assetId: this.videopageData.path
      };
      this.assetService.AddToWatchList(requestObject).subscribe(
        res => {
          if (res !== undefined && res.success === true) {
            //console.log("added to watch list");
          }
        },
        err => {
          //console.log("error in add to watch list");
          //console.log(err);
        }
      );
    }
  }

   // Default image
   setDefaultPic(event): void {
    event.target.src = this.defaultImg;
  }

  openSharePopup(): void {
    this.showSharePopup = true;
    this.shareUrl = window.location.href;
  }

}
