import { Component, OnInit, ViewChild, HostListener, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import { SearchFire } from 'src/app/services/search.fire';

import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var $: any;

import { MESSAGES_EN, MESSAGES_HI,AppCoreService,AppTokenService,NavigationService,
  NavigationDataObject, NavigationItem, Constants } from './index';
import { Subscription } from 'rxjs';
import { UserData } from 'src/app/models/user.data-object';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NavigationService, Constants]
})
export class HeaderComponent implements OnInit, AfterViewChecked { 
  _navigationService: NavigationService;
  navigationData: NavigationDataObject;
  _router: Router;
  public isLogin: boolean;
  public searchEnabled = false;
  public searchText = '';
  public selectedLang = '';
  public searchResults = [];
  public pdTxtNoResult: any;
  public moreResults: any;
  queryField: FormControl;
  searchForm: FormGroup;
  pdSignIn = '';
  txtFavourites = '';
  txtWatchList = '';
  txtPlans = '';
  txtOrders = '';
  txtMyAccount = '';
  mylogOut = '';
  selectedLanguage = 'en';
  searchRequest: boolean;
  showSubscriptionPopup: boolean;
  msgSignOut = '';
  txtCancel = '';
  txtYes = '';
  activePath:string;
  emailmobile: any;
  userId = 0;
  @ViewChild('dismiss', {static: false}) dismiss;
  subscription: Subscription;
  constructor(
    private appCoreService: AppCoreService,
    private appTokenService: AppTokenService,
    navigationService: NavigationService,
    router: Router,
    public Global: Constants,
    private search: SearchFire,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this._router = router;
    this._navigationService = navigationService;
    this.queryField = new FormControl();
    this.searchForm = this.fb.group({ search: this.queryField }); 
  }

  ngOnInit(): void {
    this.searchResults = [];
    this.searchEnabled = false;
    this.showSubscriptionPopup = false;
    this.emailmobile = this.appCoreService.GetUserEmailMobile();
    if (this.appCoreService.isUserLoggedIn.value) {
      const userData: UserData = this.appCoreService.GetUserData();
      this.userId = userData.userId;    
    }
    this.appCoreService.CheckLanguageSelected().subscribe(lang => {
      if (lang !== undefined) {
        this.appTokenService.CheckToken().subscribe(token => {
          if (token !== undefined && token) {
            this.getNavigationList();
          } else if (!token) {
            this.appTokenService.CreateToken().subscribe(new_token => {
              if (new_token !== undefined) {
                this.appTokenService.SetToken(new_token);
              }
            });
          }
        });

        // set the language
        this.selectedLang = MESSAGES_EN.ENGLISH_TXT;
        const selectedLanguage = this.appCoreService.GetLanguage();
        this.pdTxtNoResult = MESSAGES_EN.txtNoResult;
        this.moreResults = MESSAGES_EN.moreResults;

        this.pdSignIn = MESSAGES_EN.pdSignIn;
        this.txtFavourites = MESSAGES_EN.txtFavourites;
        this.txtWatchList = MESSAGES_EN.txtWatchList;
        this.txtPlans = MESSAGES_EN.txtPlans;
        this.txtOrders = MESSAGES_EN.txtOrders;
        this.txtMyAccount = MESSAGES_EN.txtMyAccount;
        this.mylogOut = MESSAGES_EN.txtSignOut;
        this.msgSignOut = MESSAGES_EN.msgSignOut;
        this.txtCancel = MESSAGES_EN.txtCancel;
        this.txtYes = MESSAGES_EN.txtYes;

        if (selectedLanguage === this.Global.LANG_HI) {
          this.selectedLang = MESSAGES_HI.HINDI_TXT;
          this.pdTxtNoResult = MESSAGES_HI.txtNoResult;
          this.moreResults = MESSAGES_HI.moreResults;

          this.pdSignIn = MESSAGES_HI.pdSignIn;
          this.txtFavourites = MESSAGES_HI.txtFavourites;
          this.txtWatchList = MESSAGES_HI.txtWatchList;
          this.txtPlans = MESSAGES_HI.txtPlans;
          this.txtOrders = MESSAGES_HI.txtOrders;
          this.txtMyAccount = MESSAGES_HI.txtMyAccount;
          this.mylogOut = MESSAGES_HI.txtSignOut;
          this.msgSignOut = MESSAGES_HI.msgSignOut;
          this.txtCancel = MESSAGES_HI.txtCancel;
          this.txtYes = MESSAGES_HI.txtYes;
        }
      }
    });

    this.queryField.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(queryField => {
        if (queryField != undefined && queryField.length >= 3) {
          this.searchText = queryField;
          const searchObject = { keyword: queryField };
          const filterSearchString = '?filter=' + encodeURI(JSON.stringify(searchObject));
          this._navigationService.GetSearchResults(filterSearchString).subscribe(response => {
            if (response !== undefined) {
              this.searchResults = response.data.data;
            }
          });
        }
      });

    // bind user login
    this.appCoreService.CheckUserLoggedIn().subscribe(res => {
      if (res !== undefined) {
        this.isLogin = this.appCoreService.isUserLoggedIn.value;
      }
    });
    // to hide suggestion box on outside click
    $(document).on('click', function (e) {
      if ($(e.target).closest("#autoSearchDrop").length === 0) {
          $("#autoSearchDrop").hide();    
      }
      if ($(e.target).closest(".attr-nav").length === 0) {
          $(".search-box").hide();
      }     
    });   
    $(".profile-box").click(function(){
      $(".search-box").hide();
    })
    $('#search-box').on('click', function (e) {
      $(".search-box").show() 
    });
    
  }
 
  ngAfterViewChecked() {   
    this.subscription = this.appCoreService.getSetNavigationActive().subscribe((value)=>{    
        this.cdr.detectChanges();  
        if(window.location.pathname === '/home'){        
          this.activePath = 'HOME';
        } else {
          if(value){ 
            this.activePath = value;          
          } else {
            this.activePath = '';
          }
        } 
    }); 
  }
  getNavigationList(): void {
    const selectedLanguage = this.appCoreService.GetLanguage();
    const navigationObject = {
      deviceTypeId: this.Global.WEB_DEVICE_ID,
      langId: selectedLanguage === this.Global.LANG_EN ? 1 : 2
    };
    this._navigationService.Get(navigationObject).subscribe(res => {
      if (res !== undefined) {
        this.navigationData = res.data;
        this.activePath = res.data.data[0].title;        
        // store it for later retrieval
        this.appCoreService.storeNavigations(res.data);
      }
    });
  }
  public onNavigationClick(navigation: NavigationItem, reset: boolean): void {         
    this.activePath = navigation.title;      
    this.appCoreService.setSetNavigationActive(this.activePath);
    if (navigation.path === '4') {
      this._router.navigate(['channels']);
      return;
    } else {
      this._router.navigate(['', navigation.title.split(' ').join('').toLowerCase()]);
      return;
    }
  }

  public goToHomePage(): void {
    const navigationData = this.navigationData.data;
    for (const navigate of navigationData) {
      if (navigate.title.toLowerCase() === 'home') {
        this._router.navigate(['', navigate.title.toLowerCase()]);
        return;
      }
    }
  }

  onKeydown(event, value): void {
    this.searchResults = [];
    this.searchRequest = false;
    if (value.trim().length >= 3) {
      this.getSearchResults(value);
    }
    
    if (event.key === 'Enter') {
      this.suggestions(value);
      this.search.setSearchText(value);
    }
  }

  suggestions(keyword): void {
      const routeUrl = this._router.url;
      if (!routeUrl.includes('/search')) {
        this._router.navigate(['/search', keyword]);
      } else {        
        this._router.navigate(['/search', keyword]);
      }
  }

  public goToAuthentication(): void {
    this._router.navigate(['auth']);
  }

  enableSearch(): void {
    this.searchEnabled = true;
  }

  getSearchResults(searchValue): void {
    const requestObject = {
      langId: this.selectedLanguage === 'hi' ? 2 : 1,
      keyword: searchValue
    };
    const filterSearchString = '?filter=' + encodeURI(JSON.stringify(requestObject));
    this._navigationService.GetSearchResults(filterSearchString).subscribe(res => {
      
      this.search.setSearch(res);
      if (res !== undefined) {
        this.searchResults = res.data.data;
        this.searchRequest = true;
      }
    });
  }

  goToSearchPageViewMore(): void {
    this._router.navigate(['/search', this.searchText]);
  }

  goToSearchPage(asset): void {
    if (asset.type === 'asset_group') {
      const video_group_url = '/season/' + this.Global.GetTitleForUrl(asset.title) + '/' + asset.path;
      this._router.navigate([video_group_url]);    
    } else if(asset.asset_group_id != null && asset.asset_category_id != null){
      localStorage.setItem('seasonPath', asset.asset_category_id);
      const video_group_url = '/season/' + this.Global.GetTitleForUrl(asset.title) + '/' + asset.asset_group_id;
      this._router.navigate([video_group_url]); 
    }  else {
      const title = asset.title;
      this._router.navigate(['/video',this.Global.GetTitleForUrl(title), asset.path]);
    }  
    this.searchRequest = false;
  }

  public headerNavigation(path: string): void {
    this._router.navigate([path]);
  }

  public UserSignOut(): void {
    this.appCoreService.RemoveUserData();
    this.appCoreService.GetUserEmailMobile();
    this._router.navigate(['/language']);
  }

  setLanguage(language): any {
    this.appCoreService.SetLanguage(language);
    this.track_moeEvent("lang",this.emailmobile, language, null);
  }

  focusOutFunction(): void {
    this.searchEnabled = false;
  }

  closePopup(): void {
    this.showSubscriptionPopup = false;
  }

  showLogoffPopup(): void {
    this.showSubscriptionPopup = true;
    this.track_moeEvent('logout', this.emailmobile ,null, this.userId);
  }
  onHamburgerToggle(event): void{    
    if(event === 1){    
      this.getNavigationList();
    }else{ // close hamburger
      this.dismiss.nativeElement.click();
    }
  }
  ngOnDestroy() : void {
    this.subscription.unsubscribe();
  }

  track_moeEvent(eventType: string, emailMobile: any, languageselected: string, userId: any): void {
    if(emailMobile != undefined && emailMobile != null){
      var addMobile = emailMobile.match('^((\\+91-?)|0)?[0-9]{10}$');
    }
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const lang = languageselected;
    if (eventType === 'logout') {
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event('logout', { "logged_out": true, "email": emailMobile, "user_id": userId });
      }
      if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event('logout', { "logged_out": true, "mobile_number": emailMobile, "user_id": userId });
      }
    }
    if (eventType === 'lang') {
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event('language_selection_screen_opened', { "language_selection_screen_opened": true, "email": emailMobile, "language_selected": lang });
      }
      if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event('language_selection_screen_opened', { "language_selection_screen_opened": true, "mobile_number": emailMobile, "language_selected": lang });
      }

    }
  }
}
