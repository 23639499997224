export class PageCategoryDataObject {
    success: boolean;
    message: string;
    data: PageCategory[];
    iat: number;
}

export class PageCategory {
    path: string;
    title: string;
    description: string;
    isActive: number;
    sameForAllZone: number;
    displayIndexing: string;
    sameForAllProductType: number;
    pcdlmId: number;
    verticalFileName: string;
    verticalFilePath: string;
    horizontalFileName: string;
    horizontalFilePath: string;
    dtypeId: number;
    langId: number;
    exclusionText: string;
    displayType: string;
    orderBy: string;
    isPrimaryNavigationVisible: number;
    isMenuCategoryVisible: number;
    isAssetGroupVisible: number;
    isAssetCategoryVisible: number;
    isAssetVisible: number;
    label: string;
    isVisible: number;
    mapCmsUserWithWatchlist: number;
    mapCmsUserPlaylist: number;
    mapCmsUserWithFavorite: number;
    uploadVerticalImage: string;
    uploadHorizontalImage: number;
    assetMappedCount: number;
    assetGenreMappedCount: number;
    assetGroupMappedCount: number;
    assetCategoryMappedCount: number;
    castMappedCount: number;
    classificationMappedCount: number;
    sorting: string;
    type: number;
    is_loading = true;
    data: PageData[];
    genre: Genres[];
    assetGroupClassification: AssetGroupClassificationData[];
    // tslint:disable-next-line:no-inferrable-types
    isDisplay?: boolean = true;
    // tslint:disable-next-line:no-inferrable-types
    isPageCategory?: boolean = true;
    isGenrePath?: any = 0;
}

export class PageData {
    path: any;
    pageCategoryId: any;
    title: any;
    description: any;
    isActive: any;
    adlmId: any;
    deviceName: any;
    vodOrLivePosterImageFileName: any;
    vodOrLivePosterImageFilePath: any;
    vodClosedCaptionFileName: any;
    vodClosedCaptionFilePath: any;
    verticalFileName: any;
    verticalFilePath: any;
    horizontalFileName: any;
    horizontalFilePath: any;
    langaugeId: any;
    langaugeName: any;
    langaugeCode: any;
    dtypeId: any;
    langId: any;
    exclusionText: any;
    sameForAllZone: any;
    sameForAllProductType: any;
    displayIndexing: any;
    clickThrough?: string;
    label: any;
    airStartDate: any;
    airStartTimezone: any;
    airEndDate: any;
    airEndTimezone: any;
    offlineDownload: any;
    mapTrailerVideo: any;
    uploadVerticalImage: any;
    uploadHorizontalImage: any;
    seoTitle: any;
    seoKeyword: any;
    seoDescription: any;
    lyrics: any;
    duration: any;
    ratingCount: any;
    totalRating: any;
    likeCount: any;
    isUserLikes: any;
    watchedCount: any;
    isUserWatched: any;
    averageRating: any;
    type: any;
}

export class Genres {
    path: string;
    name: string;
}

export class AssetGroupClassificationData {
    path: string;
    name: string;
}
