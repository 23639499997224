import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from './../../environments/environment';

import { AppTokenService } from './app-token.service';

@Injectable()
export class FooterService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _footerGenreEndPoint = 'v1/genre';
  _footerVideosEndPoint = 'v1/genre/video';
  _footerLinksEndPoint = 'v1/footerlink';
  _footerContentEndPoint = 'v1/footerlink/';
  _footerContactEndPoint = 'v1/contact';
  _appTokenService: AppTokenService;

  constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
    this._appTokenService = appTokenService;
  }

  public GetFooterGenres(): Observable<any> {
    const full_api_url = this._apiUrl + this._footerGenreEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetFooterVideos(genreFiterString): Observable<any> {
    const full_api_url = this._apiUrl + this._footerVideosEndPoint + genreFiterString;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetFooterLinks(filterObject): Observable<any> {
    const full_api_url = this._apiUrl + this._footerLinksEndPoint + filterObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public SendContactUs(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._footerContactEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      urlParam
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetFooterLinkContent(path, footerObject): Observable<any> {
    const full_api_url = this._apiUrl + this._footerContentEndPoint + path + footerObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);

  }
}
